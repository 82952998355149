import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Page from '../Page';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import { formatRupiah, getFileName } from '../../lib/utils';
import { getDetailBorrow, getDetailLend, bookInvoice } from '../../actions/scf';
import ImageGallery from '../../components/ImageGallery';
import Swal from 'sweetalert2';
import DocumentsModal from '../Dashboard/DocumentsModal';
import { Close } from '@material-ui/icons';

const InvoiceDetail = (props) => {
  const id = props.match.params.id;
  const currentPage = id.includes('TDS-BRQ') ? 'borrow' : 'lend';
  const prevPath = currentPage === 'borrow' ? 'dashboard' : 'invoice';
  const [openImage, setOpenImage] = useState(false);
  const data = currentPage === 'borrow' ? props.dataBorrow : props.dataLend;
  const invoice = currentPage === 'borrow' ? _.get(data, 'invoice', {}) : _.get(data, 'borrower_request.invoice', {});
  const company_borrower = currentPage === 'borrow' ? _.get(data, 'company_borrower_id', {}) : _.get(data, 'borrower_request.company_borrower_id', {});
  const [transporterDocuments, setTransporterDocuments] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (currentPage === 'borrow') {
      props.getDetailBorrow(id);
    } else {
      props.getDetailLend(id);
    }
    // eslint-disable-next-line
  }, []);

  const calculateCount = () => {
    let items = _.get(invoice, 'items', []);
    let orders = items.length;
    let trip_ids = items.map(item => _.get(item, 'order.trip_id'));
    let trips = _.chain(trip_ids)
                 .filter(trip => !_.isNil(trip))
                 .uniq()
                 .value()
                 .length;

    return { trips, orders };
  }

  const handleSubmit = () => {
    let body = {
      invoices: [{id}]
    };
    
    props.bookInvoice(body).then(result => {
      if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        Swal.fire('Berhasil', `Invoice berhasil diajukan`, 'success').then(() => {
          props.history.push('/dashboard');
        });
      };
    });
  }

  const getPods = () => {
    let pod = [];
    _.each(invoice.items, item => {
      if (_.get(item, 'order.pods.length', 0) > 0) {
        pod.push(...item.order.pods);
      };
    });

    return pod;
  }

  const images = getPods().map(pod => ({ original: pod.image_url, thumbnail: pod.image_url }));

  const showDocuments = () => {
    let documents = _.get(company_borrower, 'company_registration', false);
    if (documents) setTransporterDocuments(documents);
    else {
      Swal.fire('Dokumen tidak ditemukan', '', 'error');
    }
  }
  
  return (
    <Page title="Detail Invoice">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={`/${prevPath}`}>
          {prevPath === 'dashboard' ? _.capitalize(prevPath) : 'Invoice yang Telah Didanai'}
        </Link>
        <Typography color="textPrimary">Detail Invoice</Typography>
      </Breadcrumbs>
      
      <div className="panel mt-4" style={{marginBottom: currentPage === 'borrow' ? '100px' : '20px'}}>
        <div className="panel-header">
          Informasi Invoice
        </div>
        <div className="panel-body">
          <div className="pb-4 border-dashed-bottom">
            <div className="row mb-4">
              <div className="col-4">
                <div className="text-grey">Shipper</div>
                <div className="mt-2">
                  {
                    _.get(invoice, 'target', '-') === 'company_order' ?
                    _.get(invoice, 'items[0].order.transporter_shipper.name', '-')
                    :
                    _.get(invoice, 'items[0].order.company_shipper.name', '-')
                  }
                </div>
              </div>
              <div className="col-4">
                <div className="text-grey">Transporter</div>
                <div className="mt-2">{_.get(company_borrower, 'name', '-')}</div>
              </div>
              <div className="col-4">
                <div className="text-grey">Jumlah Trip</div>
                <div className="mt-2">
                  {calculateCount().trips} Trip 
                  {calculateCount().orders > 0 &&
                    <span> ({calculateCount().orders} Order)</span>
                  }
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
              <div className="text-grey">Tanggal Pengajuan Invoice</div>
                {currentPage === 'borrow' ?
                  <div className="mt-2">
                    {_.get(data, 'created_at', false) ?
                      moment(data.created_at).format('dddd, DD MMM YYYY')
                    :
                      '-'
                    }
                  </div>
                :
                  <div className="mt-2">
                    {_.get(data, 'borrower_request.created_at', false) ?
                      moment(data.borrower_request.created_at).format('dddd, DD MMM YYYY')
                    :
                      '-'
                    }
                  </div>
                }
              </div>
              <div className="col-4">
              <div className="text-grey">Invoice Disetujui</div>
                <div className="mt-2">
                  {_.get(invoice, 'finalized_at', false) ?
                    moment(invoice.finalized_at).format('dddd, DD MMM YYYY')
                  :
                    '-'
                  }
                </div>
              </div>
              <div className="col-4">
                <div className="text-grey">Rencana Pengembalian Dana</div>
                <div className="mt-2">
                  {_.get(data, 'repayment_due_date', false) ?
                    moment(data.repayment_due_date).format('dddd, DD MMM YYYY')
                  :
                    '-'
                  }
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
                <div className="text-grey">Status</div>
                <div className="mt-2">{_.capitalize(_.get(data, 'status', '-'))}</div>
              </div>
              <div className="col-4">
                <div className="text-grey">ID Invoice</div>
                <div className="mt-2">{_.get(invoice, 'id', '-')}</div>
              </div>
              <div className="col-4">
                <div className="text-grey">Tanggal Permintaan Pencairan</div>
                {currentPage === 'borrow' ?
                  <div className="mt-2">
                    {_.get(data, 'disbursement_date', false) ?
                      moment(data.disbursement_date).format('dddd, DD MMM YYYY')
                    :
                      '-'
                    }
                  </div>
                :
                  <div className="mt-2">
                    {_.get(data, 'borrower_request.disbursement_date', false) ?
                      moment(data.borrower_request.disbursement_date).format('dddd, DD MMM YYYY')
                    :
                      '-'
                    }
                  </div>
                }
              </div>
            </div>


            {['paid', 'repaid'].includes(_.get(data, 'status', '-')) &&
              <div className="row mb-4">
                <div className="col-4">
                  <div className="text-grey">Tanggal Pembayaran</div>
                  <div className="mt-2">
                    {_.get(data, 'verify_paid_date.created_at', false) ?
                      <div>{moment(data.verify_paid_date.created_at).format('dddd, DD MMM YYYY')}</div>
                    :
                      '-'
                    }
                  </div>
                </div>
                {_.get(data, 'status') === 'repaid' &&
                  <div className="col-4">
                    <div className="text-grey">Tanggal Pengembalian</div>
                    <div className="mt-2">
                      {_.get(data, 'repayment_date.created_at', false) ?
                        moment(data.repayment_date.created_at).format('dddd, DD MMM YYYY')
                      :
                        '-'
                      }
                    </div>
                  </div>
                }
                {_.get(data, 'status') === 'repaid' &&
                  <div className="col-4">
                    <div className="text-grey">Bukti Pengembalian</div>
                    <div className="mt-2">
                      {_.get(data, 'borrower_request.proof.length', 0) > 0 ?
                        data.borrower_request.proof.map(proof => (
                          <div 
                            className="pointer text-ritase" 
                            onClick={() => setImageUrl(proof.image_url)}
                          >
                            {getFileName(proof.image_url)}
                          </div>
                        ))
                      :
                        '-'
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>

          <div className="pb-4 mt-4 border-dashed-bottom">
            <div className="s-18 mb-2">Detail Trips</div>
            <ul className="list-group location">
              <li className="list-group-item">
                <label>Origin</label>
                {_.get(invoice, 'items[0].order', false) &&
                  <strong>
                    {invoice.items[0].order.origin_contact_name} -{" "}
                    {invoice.items[0].order.origin_contact_number}
                  </strong>
                }
                {_.get(invoice, 'items[0].order.origin', false) &&
                  <label>{invoice.items[0].order.origin.address}</label>
                }
              </li>
              <li className="list-group-item">
                <label>Destination</label>
                {_.get(invoice, 'items[0].order', false) &&
                  <strong>
                    {invoice.items[0].order.destination_contact_name} -{" "}
                    {invoice.items[0].order.destination_contact_number}
                  </strong>
                }
                {_.get(invoice, 'items[0].order.destination', false) &&
                  <label>{invoice.items[0].order.destination.address}</label>
                }
              </li>
              {_.get(invoice, 'items', []).map((item, idx) => {
                if (idx === 0) return true;
                return (
                  <li key={item.id} className="list-group-item">
                    <label>Destination</label>
                    <strong>
                      {_.get(item, 'order.destination_contact_name')} - {_.get(item, 'order.destination_contact_number')}
                    </strong>
                    <label>{_.get(item, 'order.destination.address')}</label>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="pb-4 mt-4">
            <div className="s-18 mb-2">Dokumen</div>
            {images.length > 0 &&
              <div>
                <div className="row align-items-center mt-2">
                  <div className="col-6 bolder">POD dari Aplikasi</div>
                  <div className="col-6 text-right">
                    <Button 
                      color="primary" 
                      variant="contained" 
                      disableElevation
                      onClick={() => setOpenImage(true)}
                    >
                      Lihat Dokumen
                    </Button>
                  </div>
                </div>
                <hr />
              </div>
            }
            <div className="row align-items-center mt-2">
              <div className="col-6 bolder">Dokumen Legal</div>
              <div className="col-6 text-right">
                <Button 
                  color="primary" 
                  variant="contained" 
                  disableElevation
                  onClick={() => showDocuments()}
                >
                  Lihat Dokumen
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {currentPage === 'borrow' &&
        <Footer>
          <Button variant="outlined" onClick={() => window.history.back()}>Kembali</Button>
          <div>
            <div className="flex align-items-center">
              <div>
                <div className="mb-1">Nilai Invoice</div>
                <div className="text-action s-18 bolder">{formatRupiah(_.get(data, 'request_amount', 0))}</div>
              </div>
              <div>
                <Button 
                  variant="contained" 
                  color="secondary"
                  disableElevation
                  className="ml-4"
                  onClick={handleSubmit}
                >Ajukan Pendanaan</Button>
              </div>
            </div>
          </div>
        </Footer>
      }

      {openImage &&
        <ImageGallery 
          open={openImage}
          images={images}
          closeModal={() => setOpenImage(false)}
        />
      }

      {Boolean(transporterDocuments) &&
        <DocumentsModal
          open={Boolean(transporterDocuments)}
          data={transporterDocuments}
          closeModal={() => setTransporterDocuments(null)}
        />
      }

      {Boolean(imageUrl) &&
        <Dialog
          open={Boolean(imageUrl)}
          onClose={() => setImageUrl(null)}
          maxWidth="lg"
          fullWidth
          scroll="paper"
        >
          <DialogTitle onClose={() => setImageUrl(null)} className="border-bottom">
            Bukti Pengembalian
            <Close className="pointer pull-right" onClick={() => setImageUrl(null)} />
          </DialogTitle>
          <DialogContent>
            <img className="w-100" src={imageUrl} alt={imageUrl} />
          </DialogContent>
        </Dialog>
      }
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    dataBorrow: state.dashboard.detail,
    dataLend: state.invoice.detail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDetailBorrow: (id, params) => dispatch(getDetailBorrow(id, params)),
    getDetailLend: (id, params) => dispatch(getDetailLend(id, params)),
    bookInvoice: (data) => dispatch(bookInvoice(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);