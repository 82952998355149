import React from 'react';
import { connect } from 'react-redux';

const Footer = ({ children, showSidebar, style, className }) => {
  return (
    <div className={`footer ${className || ''}`} style={{...style, paddingLeft: showSidebar ? '295px' : '110px'}}>
      {children}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    showSidebar: state.sidebar.show
  }
}

export default connect(mapStateToProps)(Footer);