export const POLICY = 'GENERATE_POLICY';

export const generatePolicy = (data) => {
  return {
    type: POLICY,
    payload: {
      request: {
        url: `/upload/picture/generatePolicy`,
        method: 'post',
        data
      }
    }
  }
}