export const GET = 'GET_BANKS';

export const getBanks = () => {
  return {
    type: GET,
    payload: {
      request: {
        url: `/banks`,
        method: 'get'
      }
    }
  }
}