import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import './Dashboard.scss';
import { formatRupiah, getLevel, getSublevel, tdsSingkat } from '../../lib/utils';
import addbulk from '../../assets/icons/addbulk.svg';
import { getListDp } from '../../actions/scf';
import { Link } from 'react-router-dom';
import Pagination from '../../components/Pagination';

const DashboardDp = (props) => {
  const { is_fetching, list, meta, isBulk, showDocuments, setOpenModal, selected, setSelected, isSelected, toggleIsBulk } = props;
  const params = {
    scf_type: 'dp',
    populate: 'order.trip,company_borrower_id,order.company_shipper.company_rating.badge_level,company_borrower_id.company_rating.badge_level,company_borrower_id.company_registration'
  };

  useEffect(() => {
    props.getListDp(0, params);
    // eslint-disable-next-line
  }, []);

  const handleClick = (event, item) => {
    const isSelected = _.find(selected, s => s.id === item.id, false);
    let newSelected = [...selected];

    if (isSelected) {
      newSelected = _.filter(selected, s => s.id !== item.id);
    } else {
      newSelected.push(item);
    };

    setSelected(newSelected);
  };

  const chooseDp = item => {
    setSelected([item]);
    setOpenModal(true);
  }

  return (
    <div>
      <div className="dashboard-content">
        <div className="container-content" style={{marginBottom: isBulk ? '100px' : '20px'}}>
          <div className="row align-items-center">
            <div className="col-6">
              <div className="s-24 bolder">Daftar Down Payment</div>
            </div>
            <div className="col-6 text-right">
              {list.length > 0 && !isBulk && 
                <Button 
                  variant="outlined"
                  color="secondary" 
                  disableElevation
                  startIcon={<img src={addbulk} alt="add" />}
                  onClick={() => toggleIsBulk(true)}
                >
                  Danai Sekaligus
                </Button>
              }
            </div>
          </div>
          
          <div className="row">
            <div className="col-12">
              <TableContainer className="table-card">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {isBulk && <TableCell></TableCell>}
                      <TableCell>ID Order</TableCell>
                      <TableCell>Shipper</TableCell>
                      <TableCell>Transporter</TableCell>
                      <TableCell>DP Disetujui</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {is_fetching ?
                      <TableRow>
                        <TableCell align="center" colSpan={7} className="border-0">
                          Loading
                        </TableCell>
                      </TableRow>
                    : list.length > 0 ?
                      list.map((item, i) => {
                        const isItemSelected = isSelected(item.id);
                        return(
                          <TableRow 
                            key={i} 
                            aria-checked={isBulk && isItemSelected} 
                            selected={isBulk && isItemSelected}
                          >
                            {isBulk &&
                              <TableCell className="p-0 pt-2">
                                <Checkbox
                                  checked={isItemSelected}
                                  onClick={(event) => handleClick(event, item)}
                                  color="primary"
                                />
                              </TableCell>
                            }
                            <TableCell>
                              <div>
                                {_.get(item, 'order_id', false) ?
                                  tdsSingkat(item.order_id)
                                :
                                  '-'
                                }
                              </div>
                              <div className="mt-3">
                                {_.get(item, 'id', false) ?
                                  'SCF : ' + tdsSingkat(item.id)
                                :
                                  '-'
                                }
                              </div>
                              <Link to={{pathname: `/dp/${item.id}`, state: { prevPath: 'dashboard' }}} className="mt-4 text-ritase pointer block">Detail Down Payment</Link>
                            </TableCell>
                            <TableCell>
                              <div className="flex align-items-top">
                                {_.get(item, 'order.company_shipper.image_url', false) ?
                                  <img src={item.order.company_shipper.image_url} alt="" className="shipper_initial" />

                                :
                                  <div className="shipper_initial">
                                    { _.get(item, 'order.company_shipper.name', '').substr(0,1) }
                                  </div>
                                }
                                <div>
                                  <div className="bolder">{ _.get(item, 'order.company_shipper.name', '-') }</div>
                                  {_.get(item, 'order.company_shipper.company_type') === 'client' && 
                                    <div className="level-badge-wrapper">
                                      {_.get(item, 'order.company_shipper.company_rating.badge_level') ?
                                        <div>
                                          {_.map(Array.from('x'.repeat(getSublevel(_.get(item, 'order.company_shipper.company_rating.badge_level')))), (x, i) => (
                                            <span 
                                              key={i}
                                              className={`level-badge ${getLevel(_.get(item, 'order.company_shipper.company_rating.badge_level'))}`}
                                            > </span>
                                          ))}
                                        </div>
                                      :
                                        <div>
                                          <span 
                                            className="level-badge no_badge"
                                          > </span>
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              {_.get(item, 'company_borrower_id.name', '-')}
                              {_.get(item, 'company_borrower_id.company_type') === 'transport' && 
                                <div className="level-badge-wrapper">
                                  {_.get(item, 'company_borrower_id.company_rating.badge_level') ?
                                    <div>
                                      {_.map(Array.from('x'.repeat(getSublevel(_.get(item, 'company_borrower_id.company_rating.badge_level')))), (x, i) => (
                                        <span 
                                          key={i}
                                          className={`level-badge ${getLevel(_.get(item, 'company_borrower_id.company_rating.badge_level'))}`}
                                        > </span>
                                      ))}
                                    </div>
                                  :
                                    <div>
                                      <span 
                                        className="level-badge no_badge"
                                      > </span>
                                    </div>
                                  }
                                </div>
                              }
                              <div className="mt-3 text-ritase pointer" onClick={() => showDocuments(item)}>
                                Lihat Dokumen Legal
                              </div>
                            </TableCell>
                            <TableCell>
                              {_.get(item, 'verified_dp_at', false) ?
                                moment(item.verified_dp_at).format('ddd, DD MMM YYYY')
                              :
                                '-'
                              }
                            </TableCell>
                            <TableCell>
                              <div className="text-action s-18 bolder">
                                {formatRupiah(_.get(item, 'request_amount', 0))}
                              </div>
                              <div>
                                <Button 
                                  variant="contained" 
                                  color="secondary" 
                                  disableElevation
                                  className="mt-3 w-100"
                                  onClick={() => chooseDp(item)}
                                >Pilih</Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    :
                      <TableRow>
                        <TableCell align="center" colSpan={7} className="border-0">
                          No Data Found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <Pagination 
            count={meta.count || 0}
            limit={meta.limit || 10}
            offset={meta.offset || 0}
            onChange={(e, page) => props.getListDp(((page - 1) * 10), params)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let { is_fetching, list, meta } = state.dp;
  return {
    is_fetching, list, meta
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getListDp: (offset, params) => dispatch(getListDp(offset, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDp);