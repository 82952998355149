import { GET_CONFIG } from "../actions/config";
import _ from 'lodash';

const getConfig = (state = {}, action) => {
  switch (action.type) {
    case GET_CONFIG + '_SUCCESS':
      return _.get(action, 'payload.data', {});
    case GET_CONFIG + '_FAIL':
      return {}
    default:
      return state;
  }
}

export default getConfig;