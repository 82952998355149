import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardContent } from '@material-ui/core';
import _ from 'lodash';
import './Dashboard.scss';
import { formatRupiah } from '../../lib/utils';
import { getSummary } from '../../actions/scf';

const DashboardSummary = (props) => {
  const { summary, user } = props;

  useEffect(() => {
    props.getSummary()

    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard-summary mb-4">
      <div>
        {(user && user.is_scf_dp) &&
          <Card className="card-total mb-2" elevation={0}>
            <CardContent>
              <div className="s-14 mb-3">Total Down Payment Saat Ini</div>
              <div className="s-22 mb-3 bolder">
                {_.get(summary, 'total_dp') ? formatRupiah(summary.total_dp) : 0}
              </div>
            </CardContent>
          </Card>
        }
        <Card className="card-total" elevation={0}>
          <CardContent>
            <div className="s-14 mb-3">Total Invoice Saat Ini</div>
            <div className="s-22 mb-3 bolder">
              {_.get(summary, 'total_invoices') ? formatRupiah(summary.total_invoices) : 0}
            </div>
          </CardContent>
        </Card>
      </div>
      <div>
        {(user && user.is_scf_dp) &&
          <Card className="card-funded mb-2" elevation={0}>
            <CardContent>
              <div className="s-14 mb-3">Total Down Payment yang Didanai</div>
              <div className="s-22 mb-3 bolder">
                {_.get(summary, 'total_dp_paid') ? formatRupiah(summary.total_dp_paid) : 0}
              </div>
            </CardContent>
          </Card>
        }
        <Card className="card-funded" elevation={0}>
          <CardContent>
            <div className="s-14 mb-3">Total Invoice yang Didanai</div>
            <div className="s-22 mb-3 bolder">
              {_.get(summary, 'total_invoice_paid') ? formatRupiah(summary.total_invoice_paid) : 0}
            </div>
          </CardContent>
        </Card>
      </div>
      <div>
        {(user && user.is_scf_dp) &&
          <Card className="card-paid mb-2" elevation={0}>
            <CardContent>
              <div className="s-14 mb-3">Total Down Payment yang Terbayar</div>
              <div className="s-22 mb-3 bolder">
                {_.get(summary, 'total_dp_repaid') ? formatRupiah(summary.total_dp_repaid) : 0}
              </div>
            </CardContent>
          </Card>
        }
        <Card className="card-paid" elevation={0}>
          <CardContent>
            <div className="s-14 mb-3">Total Invoice yang Terbayar</div>
            <div className="s-22 mb-3 bolder">
              {_.get(summary, 'total_invoice_repaid') ? formatRupiah(summary.total_invoice_repaid) : 0}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let { summary } = state.dashboard;
  return {
    summary,
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSummary: (params) => dispatch(getSummary(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSummary);