export const GET_LIST = 'GET_LIST_COMPANIES';

export const getCompanies = (params) => {
  return {
    type: GET_LIST,
    payload: {
      request: {
        url: `/scf/list_borrower`,
        method: 'get',
        params
      }
    }
  }
}