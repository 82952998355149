export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const USER_TIMEOUT = 'USER_TIMEOUT';
export const ME = 'ME';
export const FORGOT = 'FORGOT_PASSWORD';
export const RESET = 'RESET_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_ME = 'UPDATE_ME';

export const login = (data) => {
  return {
    type: LOGIN,
    payload: {
      request: {
        url: `/users/v2/sign_in`,
        method: 'post',
        data
      }
    }
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
    payload: {
      request: {
        url: `/users/sign_out`,
        method: 'post'
      }
    }
  }
}

export const handleTimeout = () => {
  return {
    type: USER_TIMEOUT
  }
}

export const getMe = (params = {}) => {
  params.populate = 'company,company.bank_accounts,company.config'
  return {
    type: ME,
    payload: {
      request: {
        url: `/users/me`,
        method: 'get',
        params
      }
    }
  }
}

export const forgotPassword = (data) => {
  return {
    type: FORGOT,
    payload: {
      request: {
        url: `/users/retail/forgot`,
        method: 'post',
        data
      }
    }
  }
}

export const resetPassword = (data) => {
  return {
    type: FORGOT,
    payload: {
      request: {
        url: `/users/retail/reset`,
        method: 'post',
        data
      }
    }
  }
}

export const updatePassword = (data) => {
  return {
    type: UPDATE_PASSWORD,
    payload: {
      request: {
        url: `/users/me/password`,
        method: 'put',
        data
      }
    }
  }
}

export const updateMe = data => {
  return {
    type: UPDATE_ME,
    payload: {
      data
    }
  }
}