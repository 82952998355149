import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, List, Divider, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import logo from '../../assets/ritase-logo.svg';
import { toggleSidebar } from '../../actions/sidebar';
import _ from 'lodash';

const Sidebar = ({ location, history, classes, showSidebar, toggleSidebar, user }) => {
  let pathname = location.pathname.split('/');
  const [activeMenu, setActiveMenu] = useState(pathname[1] === '' ? 'dashboard' : pathname[1]);
  const [menus, setMenus] = useState([
    { id: 'dashboard', title: 'Dashboard', icon: 'home' },
    { id: 'invoice', title: 'My Invoice', icon: 'price' },
    { id: 'topup-plan', title: 'Rencana Pembayaran', icon: 'topup-plan' },
  ])

  useEffect(() => {
    if (user && user.is_scf_dp) {
      let menu = [...menus];
      if (!_.find(menu, item => item.id === 'dp')) {
        menu.splice(2, 0, { id: 'dp', title: 'Down Payment', icon: 'dp' });
        setMenus(menu);
      }
    }
  // eslint-disable-next-line
  }, [user])

  const onChangeMenu = (menu) => {
    setActiveMenu(menu);
    history.push('/' + menu)
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: showSidebar,
        [classes.drawerClose]: !showSidebar,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: showSidebar,
          [classes.drawerClose]: !showSidebar,
        }),
      }}
    >
      <div className={classes.toolbar}>
        {showSidebar && <img src={logo} alt="ritase" className="logo" /> }
        <IconButton onClick={toggleSidebar}>
          {showSidebar ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {menus.map((menu) => {
          let isActive = activeMenu === menu.id;
          let image = require(`../../assets/sidebar/${menu.icon}${isActive ? '-active' : ''}.svg`);

          return (
            <ListItem 
              button 
              key={menu.id} 
              onClick={() => onChangeMenu(menu.id)}
              className={clsx(classes.drawer, {
                activeMenu: isActive,
                inactiveMenu: !isActive,
              })}
            >
              <ListItemIcon><img src={image} alt={menu.id} /></ListItemIcon>
              <ListItemText primary={menu.title} />
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  );
};

const mapStateToProps = state => {
  return {
    showSidebar: state.sidebar.show,
    user: state.auth.user
  }
}

const mapDispatchToProps = dispatch => {
	return {
    toggleSidebar: () => dispatch(toggleSidebar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));