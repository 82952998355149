import { GET_BANKS, GET_VA_NUMBER, RESET_VA_NUMBER } from "../actions/virtualaccount";
import _ from 'lodash';
import { combineReducers } from "redux";

const initBanks = {
  banksLoading: false,
  listOfBanks: []
}

const banks = (state = initBanks, action) => {
  switch (action.type) {
    case GET_BANKS:
      return {
        ...state,
        banksLoading: true
      }
    case GET_BANKS + '_SUCCESS':
      return {
        ...state,
        banksLoading: false,
        listOfBanks: _.get(action, 'payload.data.rows', [])
      }
    case GET_BANKS + '_FAIL':
      return {
        ...state,
        banksLoading: false,
        listOfBanks: []
      }
    default:
      return state;
  }
}

const initVaNumber = {
  vaNumberLoading: false,
  vaNumber: {}
}

const vaNumber = (state = initVaNumber, action) => {
  switch (action.type) {
    case RESET_VA_NUMBER:
      return {
        ...state,
        vaNumberLoading: false,
        vaNumber: {}
      }
    case GET_VA_NUMBER: 
      return {
        ...state,
        vaNumberLoading: true
      }
    case GET_VA_NUMBER + '_SUCCESS':
      return {
        ...state,
        vaNumberLoading: false,
        vaNumber: _.get(action, 'payload.data', {})
      }
    case GET_VA_NUMBER + '_FAIL':
      return {
        ...state,
        vaNumberLoading: false,
        vaNumber: {}
      }
    default:
      return state;
  }
}

export default combineReducers({ banks, vaNumber });