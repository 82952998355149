export const GET_DETAIL_BORROW = 'GET_DETAIL_BORROW_SCF';
export const GET_DETAIL_LEND = 'GET_DETAIL_LEND_SCF';
export const GET_BORROW = 'GET_BORROW_SCF';
export const GET_LEND = 'GET_LEND_SCF';
export const POST_BOOK = 'BOOK_INVOICE_SCF';
export const POST_PAYMENT = 'PAYMENT_PROOF';
export const POST_PAYMENT_MIDTRANS = 'PAYMENT_MIDTRANS';
export const EXPORT = 'EXPORT_EXCEL';
export const GET_SUMMARY = 'GET_SUMMARY';
export const GET_DP = 'GET_DP_SCF';
export const GET_TOPUP_PLAN = 'GET_TOPUP_PLAN_SCF';
export const CREATE_TOPUP_PLAN = 'CREATE_TOPUP_PLAN_SCF';
export const BATCH_TOPUP_PLAN = 'GET_BATCH_TOPUP_PLAN_SCF';
export const POST_PAYMENT_TOPUP_PLAN = 'PAYMENT_PROOF_TOPUP_PLAN';
export const CANCEL_TOPUP_PLAN = 'CANCEL_TOPUP_PLAN';

export const getList = (offset = 0, params = {}) => {
  params.limit = 10;
  params.offset = offset;
  if (!params.populate) params.populate = 'invoice,company_borrower_id,confirmed_date,invoice.items,invoice.items.order,invoice.items.order.trip,invoice.items.order.trip.company_broker,invoice.items.order.company_shipper.company_rating.badge_level,company_borrower_id.company_registration,company_borrower_id.company_rating.badge_level,invoice.additional_fees,invoice.items.order.transporter_shipper';
  if (!params.status) params.status = 'confirmed';
  params.is_lender = 1;

  return {
    type: GET_BORROW,
    payload: {
      request: {
        url: `/scf/list_borrow_request`,
        method: 'get',
        params
      }
    }
  }
}

export const getDetailBorrow = (id, params = {}) => {
  if (!params.populate) params.populate = 'invoice,company_borrower_id,confirmed_date,invoice.items,invoice.items.order.pods,invoice.items.order.trip,invoice.items.order.trip.company_broker,invoice.items.order.origin,invoice.items.order.destination,invoice.items.order.company_shipper,company_borrower_id.company_registration,invoice.items.order.transporter_shipper';

  return {
    type: GET_DETAIL_BORROW,
    payload: {
      request: {
        url: `/scf/${id}/borrower_request`,
        method: 'get',
        params
      }
    }
  }
}

export const getLendList = (offset = 0, params = {}) => {
  params.limit = 10;
  params.offset = offset;
  if (!params.populate) params.populate = 'borrower_request,borrower_request.invoice,borrower_request.company_borrower_id,borrower_request.confirmed_date,borrower_request.invoice.items.order,borrower_request.invoice.items.order.trip.company_broker,approved_date,proof,borrower_request.company_borrower_id.bank_accounts,borrower_request.invoice.items.order.company_shipper,repayment_date,borrower_request.proof,borrower_request.company_borrower_id.company_registration,verify_paid_date,topup_plan,borrower_request.invoice.items.order.transporter_shipper';

  return {
    type: GET_LEND,
    payload: {
      request: {
        url: `/scf/list_lend_request`,
        method: 'get',
        params
      }
    }
  }
}

export const getDetailLend = (id, params = {}) => {
  if (!params.populate) params.populate = 'borrower_request,borrower_request.invoice,borrower_request.company_borrower_id,borrower_request.confirmed_date,borrower_request.invoice.items.order.pods,borrower_request.invoice.items.order.trip.company_broker,approved_date,borrower_request.invoice.items.order.origin,borrower_request.invoice.items.order.destination,borrower_request.invoice.items.order.company_shipper,repayment_date,borrower_request.company_borrower_id.company_registration,verify_paid_date,company_lender_id.config,borrower_request.proof,borrower_request.invoice.items.order.transporter_shipper';

  return {
    type: GET_DETAIL_LEND,
    payload: {
      request: {
        url: `/scf/${id}/lender_request`,
        method: 'get',
        params
      }
    }
  }
}

export const bookInvoice = (data) => {
  return { 
    type: POST_BOOK,
    payload: {
      request: {
        url: `/scf/book_invoice`,
        method: 'post',
        data
      }
    }
  }
}

export const paymentProof = (id, data) => {
  return {
    type: POST_PAYMENT,
    payload: {
      request: {
        url: `/scf/${id}/paid`,
        method: 'post',
        data
      }
    }
  }
}

export const payMidtrans = (id) => {
  return {
    type: POST_PAYMENT_MIDTRANS,
    payload: {
      request: {
        url: `/midtrans/scf?scf_id=${id}`,
        method: 'get'
      }
    }
  }
}

export const exportExcel = (offset = 0, params = {}, limit = 10) => {
  params.limit = limit;
  params.offset = offset;
  if (!params.populate) params.populate = 'borrower_request,borrower_request.invoice,borrower_request.company_borrower_id,borrower_request.confirmed_date,borrower_request.invoice.items.order,borrower_request.invoice.items.order.trip.company_broker,approved_date,proof,borrower_request.company_borrower_id.bank_accounts,borrower_request.invoice.items.order.company_shipper,repayment_date,borrower_request.proof,borrower_request.company_borrower_id.company_registration,verify_paid_date';

  return {
    type: EXPORT,
    payload: {
      request: {
        url: `/scf/list_lend_request`,
        method: 'get',
        params
      }
    }
  }
}

export const getSummary = (params={}) => {
  return {
    type: GET_SUMMARY,
    payload: {
      request: {
        url: `/scf/summary`,
        method: 'get',
        params
      }
    }
  }
}

export const getListDp = (offset = 0, params = {}) => {
  params.limit = 10;
  params.offset = offset;
  if (!params.scf_type) params.scf_type = 'dp';
  if (!params.populate) params.populate = 'order.trip,company_borrower_id,order.company_shipper.company_rating.badge_level,company_borrower_id.company_rating.badge_level,company_borrower_id.company_registration';
  if (!params.status) params.status = 'confirmed';
  params.is_lender = 1;

  return {
    type: GET_DP,
    payload: {
      request: {
        url: `/scf/list_borrow_request`,
        method: 'get',
        params
      }
    }
  }
}

export const getTopupPlan = (offset = 0, params = {}) => {
  params.limit = 10;
  params.offset = offset;
  if (!params.populate) params.populate = 'borrower_request.order,borrower_request.company_borrower_id,borrower_request.order.company_shipper.company_rating.badge_level,borrower_request.company_borrower_id.company_rating.badge_level,borrower_request.company_borrower_id.company_registration,borrower_request.company_borrower_id.bank_accounts,borrower_request.invoice.items.order.company_shipper.company_rating.badge_level,topup_plan,borrower_request.invoice.items.order.transporter_shipper';
  
  return {
    type: GET_TOPUP_PLAN,
    payload: {
      request: {
        url: `/scf/topup_plan`,
        method: 'get',
        params
      }
    }
  }
}

export const createTopupPlan = (data) => {
  return {
    type: CREATE_TOPUP_PLAN,
    payload: {
      request: {
        url: `/scf/topup_plan`,
        method: 'post',
        data
      }
    }
  }
}

export const getBatchTopupPlan = (offset = 0, params = {}, limit = 10) => {
  params.limit = limit;
  params.offset = offset;
  if (!params.populate) params.populate = 'borrower_request.order,borrower_request.company_borrower_id,borrower_request.order.company_shipper.company_rating.badge_level,borrower_request.company_borrower_id.company_rating.badge_level,borrower_request.company_borrower_id.company_registration,borrower_request.company_borrower_id.bank_accounts,borrower_request.invoice.items.order.company_shipper.company_rating.badge_level';

  return {
    type: BATCH_TOPUP_PLAN,
    payload: {
      request: {
        url: `/scf/topup_plan`,
        method: 'get',
        params
      }
    }
  }
}

export const paymentProofTopupPlan = (id, data) => {
  return {
    type: POST_PAYMENT_TOPUP_PLAN,
    payload: {
      request: {
        url: `/scf/topup_plan/${id}/paid`,
        method: 'post',
        data
      }
    }
  }
}

export const cancelTopupPlan = (id) => {
  return {
    type: CANCEL_TOPUP_PLAN,
    payload: {
      request: {
        url: `/scf/topup_plan/${id}/cancel`,
        method: 'post',
      }
    }
  }
}