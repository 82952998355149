import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Snackbar, Accordion, AccordionDetails, AccordionSummary, Typography, CircularProgress } from '@material-ui/core';
import mandiri from '../../assets/icons/bank-mandiri.svg';
import bca from '../../assets/icons/bank-bca.svg';
import bni from '../../assets/icons/bank-bni.svg';
import bri from '../../assets/icons/bank-bri.svg';
import other from '../../assets/icons/bank.svg';
import bcaIcon from '../../assets/icons/bank/bca.png';
import mandiriIcon from '../../assets/icons/bank/mandiri.png';
import briIcon from '../../assets/icons/bank/bri.png';
import bniIcon from '../../assets/icons/bank/bni.png';
import permataIcon from '../../assets/icons/bank/permata.png';
// import midtrans from '../../assets/icons/midtrans.png';
import { ChevronRight, ChevronLeft, Close, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import { generatePolicy } from '../../actions/upload';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { Alert } from '@material-ui/lab';
import { paymentProof, payMidtrans, paymentProofTopupPlan } from '../../actions/scf';
import { formatRupiah } from '../../lib/utils';
import { getBanks, getVaNumber, resetVaNumber } from '../../actions/virtualaccount';
import { getConfig } from '../../actions/config';

const PaymentMethodModal = (props) => {
  const { 
    open, 
    closeModal, 
    item, 
    onFinish, 
    // config, 
    listOfBanks,
    banksLoading,
    vaNumber,
    vaNumberLoading,
    paymentType,
    isTopupPlan
  } = props;
  // const paymentType = 'xendit'; // xendit or manual
  const [step, setStep] = useState(0);
  const [fileImage, setFileImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [notification, setNotification] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const showNotification = Boolean(notification);
  const bank_id = _.get(item, 'borrower_request.company_borrower_id.bank_accounts[0].bank_id', '');

  useEffect(() => {
    if (paymentType === 'xendit') {
      props.getBanks(paymentType);
    }
    // props.getConfig({ key : 'wallet' });
    
    // eslint-disable-next-line
  }, []);

  const hideNotification = (e, reason) => {
    if (reason === 'clickaway')  return;

    setNotification(null);
  }

  const dataBanks = [
    {
      key: 'bca',
      xendit_bank_code: 'BCA',
      name: 'Transfer Bank BCA',
      instructions: [
          { method: 'ATM', steps: [`Masukkan kartu ATM dan PIN BCA Anda`, `Di menu utama, pilih "Transaksi Lainnya". Pilih "Transfer". Pilih "Ke BCA Virtual Account"`, `Masukkan nomor Virtual Account anda`, `Pastikan data Virtual Account Anda benar, kemudian masukkan angka yang perlu Anda bayarkan, kemudian pilih "Benar"`, `Cek dan perhatikan konfirmasi pembayaran dari layar ATM, jika sudah benar pilih "Ya", atau pilih "Tidak" jika data di layar masih salah`, `Transaksi Anda sudah selesai. Pilih "Tidak" untuk tidak melanjutkan transaksi lain`, `Setelah transaksi pembayaran Anda selesai, invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit`]},
          { method: 'Internet Banking', steps: [`Login ke KlikBCA Individual`, `Pilih "Transfer", kemudian pilih "Transfer ke BCA Virtual Account"`, `Masukkan nomor Virtual Account anda`, `Pilih "Lanjutkan" untuk melanjutkan pembayaran`, `Masukkan "RESPON KEYBCA APPLI 1" yang muncul pada Token BCA Anda, lalu klik tombol "Kirim"`, `Transaksi Anda sudah selesai`, `Setelah transaksi pembayaran Anda selesai, invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit.`]},
          { method: 'Mobile Banking', steps: ['Buka Aplikasi BCA Mobile', 'Pilih "m-BCA", kemudian pilih "m-Transfer"', 'Pilih "BCA Virtual Account"', 'Masukkan nomor Virtual Account anda, lalu pilih "OK"', 'Klik tombol "Send" yang berada di sudut kanan atas aplikasi untuk melakukan transfer', 'Klik "OK" untuk melanjutkan pembayaran', 'Masukkan PIN Anda untuk meng-otorisasi transaksi', 'Setelah transaksi pembayaran Anda selesai, invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit.']}
      ],
      notes: [
          'Silahkan lakukan transfer sebesar total yang harus dibayarkan'
      ],
      icon: bcaIcon
    },
    {
        key: 'mandiri',
        xendit_bank_code: 'MANDIRI',
        name: 'Transfer Bank Mandiri',
        instructions: [
            { method: 'ATM', steps: [`Masukkan kartu ATM dan pilih "Bahasa Indonesia"`, `Ketik nomor PIN dan tekan BENAR`, `Pilih menu “BAYAR/BELI” `, `Pilih menu “MULTI PAYMENT”`, `Ketik kode perusahaan: "88608" (Xendit 88608) / "88908" (Xendit 88908) (Sesuaikan dengan nomor VA anda), tekan "BENAR"`, `Masukkan nomor Virtual Account dan tekan “BENAR”`, `Isi NOMINAL, kemudian tekan "BENAR"`, `Muncul konfirmasi data customer. Pilih Nomor 1 sesuai tagihan yang akan dibayar, kemudian tekan "YA"`, `Muncul konfirmasi pembayaran. Tekan "YA" untuk melakukan pembayaran`, `Bukti pembayaran dalam bentuk struk agar disimpan sebagai bukti pembayaran yang sah dari Bank Mandiri`, `Transaksi Anda sudah selesai`]},
            { method: 'Internet Banking', steps: [`Kunjungi website Mandiri Internet Banking: <a class="link" target="_blank" href="https://ibank.bankmandiri.co.id/retail3/">https://ibank.bankmandiri.co.id/retail3/</a>`, `Login dengan memasukkan USER ID dan PIN`, `Pilih "Pembayaran"`, `Pilih "Multi Payment"`, `Pilih "No Rekening Anda"`, `Pilih Penyedia Jasa "Xendit 88608" / "Xendit 88908" (Sesuaikan dengan nomor VA anda)`, `Pilih "No Virtual Account"`, `Masukkan nomor Virtual Account anda`, `Masuk ke halaman konfirmasi 1`, `Apabila benar/sesuai, klik tombol tagihan TOTAL, kemudian klik "LANJUTKAN"`, `Masuk ke halaman konfirmasi 2`, `Masukkan Challenge Code yang dikirimkan ke Token Internet Banking Anda, kemudian klik "Kirim"`, `Anda akan masuk ke halaman konfirmasi jika pembayaran telah selesai`]},
            { method: 'Mobile Banking', steps: [`Log in Mobile Banking Mandiri Online (Install Mandiri Online by PT. Bank Mandiri (Persero Tbk.) dari App Store: <a class="link" target="_blank" href="https://itunes.apple.com/id/app/mandiri-online/id1117312908?mt=8">https://itunes.apple.com/id/app/mandiri-online/id1117312908?mt=8</a> atau Play Store: <a class="link" target="_blank" href="https://play.google.com/store/apps/details?id=com.bankmandiri.mandirionline&hl=en">https://play.google.com/store/apps/details?id=com.bankmandiri.mandirionline&hl=en</a>)`, `Klik “Icon Menu” di sebelah kiri atas`, `Pilih menu “Pembayaran”`, `Pilih “Buat Pembayaran Baru”`, `Pilih “Multi Payment”`, `Pilih Penyedia Jasa “Xendit 88608” / “Xendit 88908” (Sesuaikan dengan nomor VA anda)`, `Pilih “No. Virtual”`, `Masukkan no virtual account dengan kode perusahaan “88608” / “88908” (Sesuaikan dengan nomor VA anda) lalu pilih “Tambah Sebagai Nomor Baru”`, `Masukkan “Nominal” lalu pilih “Konfirmasi”`, `Pilih “Lanjut”`, `Muncul tampilan konfirmasi pembayaran`, `Scroll ke bawah di tampilan konfirmasi pembayaran lalu pilih “Konfirmasi”`, `Masukkan “PIN” dan transaksi telah selesai`]}
        ],
        notes: [
            'Silahkan lakukan transfer sebesar total yang harus dibayarkan'
        ],
        icon: mandiriIcon
    },
    {
        key: 'bri',
        xendit_bank_code: 'BRI',
        name: 'Transfer Bank BRI',
        instructions: [
            { method: 'ATM', steps: [`Pilih menu Transaksi Lain`, `Pilih menu Lainnya`, `Pilih menu Pembayaran`, `Pilih menu Lainnya`, `Pilih menu BRIVA`, `Masukkan Nomor BRI Virtual Account (Contoh: 26215-xxxxxxxxxxxx), lalu tekan “Benar”`, `Konfirmasi pembayaran, tekan “Ya” bila sesuai`]},
            { method: 'Internet Banking', steps: [`Masukan User ID dan Password`, `Pilih menu Pembayaran`, `Pilih menu BRIVA`, `Pilih rekening Pembayar`, `Masukkan Nomor Virtual Account BRI Anda (Contoh: 26215-xxxxxxxxxxxx)`, `Masukkan nominal yang akan dibayar`, `Masukkan password dan Mtoken anda`]},
            { method: 'Mobile Banking', steps: [`Log in ke Mobile Banking`, `Pilih menu Pembayaran`, `Pilih menu BRIVA`, `Masukkan nomor BRI Virtual Account dan jumlah pembayaran`, `Masukkan nomor PIN anda`, `Tekan "OK" untuk melanjutkan transaksi`, `Transaksi berhasil`, `SMS konfirmasi akan masuk ke nomor telepon anda`]}
        ],
        notes: [
            'Silahkan lakukan transfer sebesar total yang harus dibayarkan'
        ],
        icon: briIcon
    },
    {
        key: 'bni',
        xendit_bank_code: 'BNI',
        name: 'Transfer Bank BNI',
        instructions: [
            { method: 'ATM', steps: [`Masukkan Kartu Anda.`, `Pilih Bahasa.`, `Masukkan PIN ATM Anda.`, `Pilih "Menu Lainnya".`, `Pilih "Transfer".`, `Pilih Jenis rekening yang akan Anda gunakan (Contoh" "Dari Rekening Tabungan").`, `Pilih "Virtual Account Billing".`, `Masukkan nomor Virtual Account Anda (contoh: 88089999XXXXXX).`, `Konfirmasi, apabila telah sesuai, lanjutkan transaksi.`, `Transaksi Anda telah selesai`]},
            { method: 'Internet Banking', steps: [`Ketik alamat <a class="link" target="_blank" href="https://ibank.bni.co.id">https://ibank.bni.co.id</a> kemudian klik "Enter".`, `Masukkan User ID dan Password.`, `Pilih menu "Transfer"`, `Pilih "Virtual Account Billing".`, `Kemudian masukan nomor Virtual Account Anda (contoh: 88089999XXXXXX) yang hendak dibayarkan. Lalu pilih rekening debet yang akan digunakan. Kemudian tekan "Lanjut".`, `Periksa ulang mengenai transaksi yang anda ingin lakukan.`, `Masukkan Kode Otentikasi Token.`, `Pembayaran Anda berhasil.`]},
            { method: 'Mobile Banking', steps: [`Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password`, `Pilih menu "Transfer"`, `Pilih Menu "Virtual Account Billing" kemudian pilih rekening debet`, `Masukkan nomor Virtual Account Anda (contoh: 88089999XXXXXX) pada menu "input baru".`, `Konfirmasi transaksi anda`, `Masukkan Password Transaksi`, `Pembayaran Anda telah berhasil` ]}
        ],
        notes: [
            'Silahkan lakukan transfer sebesar total yang harus dibayarkan'
        ],
        icon: bniIcon
    },
    {
        key: 'permata',
        xendit_bank_code: 'PERMATA',
        name: 'Transfer Bank Permata',
        instructions: [
            { method: 'ATM PRIMA/ALTO', steps: [`Pada menu utama, pilih transaksi lain`, `Pilih Pembayaran Transfer`, `Pilih pembayaran lainnya`, `Pilih pembayaran Virtual Account`, `Masukkan nomor virtual account anda`, `Pada halaman konfirmasi, akan muncul nominal yang dibayarkan, nomor, dan nama merchant, lanjutkan jika sudah sesuai`, `Pilih sumber pembayaran anda dan lanjutkan`, `Transaksi anda selesai`, `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`]},
            { method: 'Permata Mobile X', steps: [`Buka Permata Mobile X dan Login`, `Pilih Pay "Pay Bills" / "Pembayaran Tagihan"`, `Pilih "Virtual Account"`, `Masukkan Nomor Virtual Account anda`, `Detail pembayaran anda akan muncul di layar`, `Nominal yang ditagihkan akan muncul di layar. Pilih sumber pembayaran`, `Konfirmasi transaksi anda`, `Masukkan kode response token anda`, `Transaksi anda telah selesai`, `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`]},
            { method: 'Permata Mobile', steps: [`Buka Permata Mobile dan Login`, `Pilih Pay "Pay Bills" / "Pembayaran Tagihan"`, `Pilih menu "Transfer"`, `Pilih sumber pembayaran`, `Pilih "Daftar Tagihan Baru"`, `Masukan nomor Virtual Account Anda`, `Periksa ulang mengenai transaksi yang anda ingin lakukan`, `Konfirmasi transaksi anda`, `Masukkan SMS token respons`, `Pembayaran Anda berhasil`, `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`]},
            { method: 'Internet Banking Permata (PermataNet)', steps: [`Buka situs <a class="link" target="_blank" href="https://new.permatanet.com">https://new.permatanet.com</a> dan login`, `Pilih menu "pembayaran".`, `Pilih menu "Pembayaran Tagihan".`, `Pilih "Virtual Account"`, `Pilih sumber pembayaran`, `Pilih menu "Masukkan Daftar Tagihan Baru"`, `Masukan nomor Virtual Account Anda`, `Konfirmasi transaksi anda`, `Masukkan SMS token response`, `Pembayaran Anda berhasil`, `Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.`,]}
        ],
        notes: [
            'Silahkan lakukan transfer sebesar total yang harus dibayarkan'
        ],
        icon: permataIcon
    },
  ];

  const [banks, setBanks] = useState(dataBanks);

  // useEffect(() => {
  //   banks.forEach(bank => {
  //     bank.admin_fee = _.get(config, 'xendit_admin_fees', null)
  //   });
  //   setBanks(banks);
  // }, [config])

  const ISODateString = (d) => {
    function pad(n) {return n<10 ? '0'+n : n}
    return '' + d.getUTCFullYear()
      + pad(d.getUTCMonth()+1)
      + pad(d.getUTCDate())+'T'
      + '000000Z'
  }

  const handleFileChange = (file) => {
    if (file) {
      var extension = file.name.substr(file.name.lastIndexOf('.')+1);
      if (!['png', 'jpg', 'jpeg'].includes(extension.toLowerCase())) {
        setNotification({type: 'error', text: 'Allowed files : PNG, JPG, JPEG'});
        return;
      }

      var date = new Date();
      var body = {
        currentDate: date.toISOString(),
        folderName: "users/",
        redirectURL: "",
        app: "ritase"
      }

      props.generatePolicy(body).then(result => {
        if (result.type === 'GENERATE_POLICY_FAIL') {
          Swal.fire('Error!', result.error.message, 'error');
        } else if (_.get(result, 'payload.data.errorMessage')) {
          Swal.fire('Error!', result.payload.data.errorMessage, 'error');
        } else {
          var isoDate = ISODateString(date);
          let res = result.payload.data;

          var bodyFormData = new FormData();
          bodyFormData.append('key', "users/" + res.imageName + "." + extension);
          bodyFormData.append('acl', "public-read");
          bodyFormData.append('Content-Type', "image/");
          bodyFormData.append('x-amz-meta-uuid', "14365123651274");
          bodyFormData.append('X-Amz-Credential', process.env.REACT_APP_S3_ACCESSKEY + '/' + isoDate.split('T')[0] + '/ap-southeast-1/s3/aws4_request')
          bodyFormData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256')
          bodyFormData.append('X-Amz-Date', isoDate)
          bodyFormData.append('x-amz-meta-tag', '')
          bodyFormData.append('success_action_status', '200')
          bodyFormData.append('Policy', res.data.s3Policy)
          bodyFormData.append('X-Amz-Signature', res.data.s3Signature)
          bodyFormData.append('file', file)
          
          Axios({
            method: 'post',
            url: process.env.REACT_APP_S3_PATH,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
              //handle success
              setFileImage(file);
              setImageUrl(`${process.env.REACT_APP_S3_PATH}users/${res.imageName}.${extension}`);
          }).catch(response => {
              //handle error
              Swal.fire('Error!', 'Upload foto gagal', 'error');
          });
        };
      })
    }
  }

  const removeImage = () => {
    setFileImage(null);
    setImageUrl('');
  }

  const uploadPaymentProof = () => {
    let id = item.id;
    let body = {
      image_urls: [imageUrl]
    }

    const uploadPayment = isTopupPlan ? props.paymentProofTopupPlan(id, body) : props.uploadPayment(id, body)
    const typeFail = isTopupPlan ? 'PAYMENT_PROOF_TOPUP_PLAN_FAIL' : 'PAYMENT_PROOF_FAIL'

    uploadPayment.then(result => {
      if (result.type === typeFail) {
        Swal.fire('Error!', result.error.message, 'error');
      } else if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        closeModal();
        Swal.fire('Berhasil', `Bukti pembayaran berhasil disimpan`, 'success').then(() => {
          onFinish();
        });
      };
    })
  }

  const onSelectBank = (key) => {
    let bank = banks.find(b => b.key === key);
    setSelectedBank(bank);
    setStep(1);
  }

  useEffect(() => {
    if (selectedBank) {
      const body = {
        bank_id : _.get(selectedBank, 'bank_id'),
      }
      if (isTopupPlan) {
        body.scf_topup_plan_id = _.get(item, 'id');
        body.account_id = _.get(item, 'company_borrower_id');
      } else {
        body.scf_lend_request_id = _.get(item, 'id') 
        body.account_id = _.get(item, 'borrower_request.company_borrower_id.id')
      }
      props.getVaNumber('xendit', body);
    }

    // eslint-disable-next-line
  }, [selectedBank])

  useEffect(() => {
    banks.forEach(bank => {
      bank.admin_fee = 5000;
      let tempBank = listOfBanks.find(b => b.bank_code === bank.xendit_bank_code);
      bank.bank_id = tempBank && tempBank.id;
    });
    setBanks(banks);

    // eslint-disable-next-line
  },  [listOfBanks])

  const goBack = () => {
    if (step === 1) {
      props.resetVaNumber();
      setSelectedBank(null);
    }
    setStep(step - 1);
  }

  const onCloseModal = () => {
    props.resetVaNumber();
    setSelectedBank(null);
    closeModal();
  }

  const getTotalTransaction = () => {
    const request_amount = isTopupPlan ? _.get(item, 'price') : _.get(item, 'borrower_request.request_amount', 0)
    const admin_fee = _.get(selectedBank, 'admin_fee', 0);

    return formatRupiah(request_amount + admin_fee);
  }

  // const handleMidtrans = () => {
  //   closeModal();
  //   /* global snap */
  //   snap.show();
  //   props.payMidtrans(item.id).then(result => {
  //     if (result.type === 'PAYMENT_MIDTRANS_FAIL') {
  //       snap.hide();
  //       Swal.fire('Error!', result.error.message, 'error');
  //     } else if (_.get(result, 'payload.data.errorMessage')) {
  //       snap.hide();
  //       let errors = {
  //         'NOT_FOUND': 'Invoice tidak dapat ditemukan',
  //         'HAS_BEEN_PAID': 'Invoice telah dibayar',
  //         'TIME_LIMIT_EXCEEDED': 'Batas waktu pembayaran telah habis',
  //       }
  //       let errorCode = _.get(result, 'payload.data.ERR_CODE', '');
  //       var errorMessage = result.payload.data.errorMessage.split('\n')[0];
  //       if (Object.keys(errors).includes(errorCode)) {
  //         errorMessage = errors[errorCode];
  //       };
  //       Swal.fire('Error!', errorMessage, 'error');
  //     } else {
  //       const { token } = result.payload.data;
  //       if (!token) {
  //         throw new Error('Pembayaran kamu tidak sukses, mohon dicoba lagi!');
  //       }

  //       /* global Promise */
  //       var midtransResponse = new Promise((resolve, reject) => {
  //         return snap.pay(token, {
  //           onSuccess: resolve,
  //           onPending: resolve,
  //           onError: reject,
  //           onClose: resolve,
  //         });
  //       });

  //       midtransResponse.then(response => {
  //         snap.hide();
  //         window.location.reload();
  //       });
  //     };
  //   })
  // }

  return (
    <Dialog
      open={open}
      onClose={() => onCloseModal()}
      maxWidth="xs"
      fullWidth
      scroll="paper"
      className="payment-method-modal"
    >
      <DialogTitle onClose={() => onCloseModal()} className="border-bottom">
        { [1,2].includes(step) ? <ChevronLeft className="pointer pull-left" style={{ fontSize: '2rem' }} onClick={() => goBack() } /> : '' }
        {step === 2 ? 'Upload Bukti Pembayaran' : 'Metode Pembayaran'}
        <Close className="pointer pull-right" onClick={() => onCloseModal()} />
      </DialogTitle>
      {step === 1 ? 
        <DialogContent>
          <div className="s-16 bolder mt-3">Detail Rekening</div>
          <div className="row mt-3">
            <div className="col-5">Transfer ke</div>
            <div className="col-7 text-right">
              { paymentType === 'xendit' 
                ? <img src={selectedBank.icon} alt={selectedBank.icon} style={{width: '70px'}} />
                : <span>{_.get(item, 'borrower_request.company_borrower_id.bank_accounts[0].bank_name', '-')}</span>
              }
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">{ paymentType === 'xendit' ? 'Nomor virtual account' : 'Nomor rekening' }</div>
            <div className="col-7 text-right text-action s-16">
              {
                paymentType === 'xendit' && !vaNumberLoading 
                ? <span>{ _.get(vaNumber, 'account_number', '-') }</span>
                : paymentType === 'xendit' && vaNumberLoading
                ? <CircularProgress size={12} />
                : <span>{_.get(item, 'borrower_request.company_borrower_id.bank_accounts[0].account_number', '-')}</span>
              }
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">Nama akun</div>
            <div className="col-7 text-right">
              {
                paymentType === 'xendit' && !vaNumberLoading 
                ? <span>{ _.get(vaNumber, 'name', '-') }</span>
                : paymentType === 'xendit' && vaNumberLoading
                ? <CircularProgress size={12} />
                : <span>{_.get(item, 'borrower_request.company_borrower_id.bank_accounts[0].name', '-')}</span>
              }
            </div>
          </div>
          { paymentType === 'xendit'
            ? 
            <div className="row mt-3">
              <div className="col-5">Total</div>
              <div className="col-7 text-right">
              { !vaNumberLoading 
                  ? <b>{getTotalTransaction()}</b>
                  : <CircularProgress size={12} />
                } 
              </div>
            </div>
            : ''
          }

          { 
            paymentType === 'xendit' ?
            <div>
              <div className="mt-4 mb-3">
                Silakan lakukan transfer ke rekening di atas sesuai dengan biaya yang tertera. Klik <strong>"Saya sudah bayar"</strong> jika Anda sudah membayar.
              </div>
              <div className="s-16 bolder mt-4">Notes</div>
              <div className="mt-4 mb-3">
                <ul className="p-0 pl-3" style={{lineHeight: '1.5'}}>
                  {
                    selectedBank.notes.map((note, i) => (
                      <li key={i} className="mb-3">{note}</li>
                    ))
                  }
                  {
                    selectedBank.admin_fee ? <li className="mb-3">Admin Fee : { formatRupiah(selectedBank.admin_fee) }</li> : ''
                  }
                </ul>
              </div>
              <div className="s-16 bolder mt-4">Metode Transfer</div>
              { 
                selectedBank.instructions.map(instruction => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>{_.get(instruction, 'method')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ol className="p-0 pl-3" style={{lineHeight: '1.5'}}>
                        {
                          instruction.steps.map(step => (
                            <li className="mb-3" dangerouslySetInnerHTML={{__html: step }} />
                          ))
                        }
                      </ol>
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </div>
          : 
            <div className="mt-4 mb-3">
              Silakan lakukan transfer ke rekening di atas dengan jumlah topup yang diinginkan. Klik <strong>"Saya sudah bayar"</strong> jika Anda sudah membayar.
            </div>
          }
        </DialogContent>
      : step === 2 ? 
        <DialogContent>
          <div className="row mt-3 align-items-center">
            <div className="col-6 bolder">Bukti Pembayaran</div>
            <div className="col-6 text-right">
              <Input 
                type="file" 
                onChange={(e) => handleFileChange(_.get(e, 'target.files[0]', null))} 
                accept="image/*" 
                id="payment-proof"
                style={{display: 'none'}}
              />
              <label htmlFor="payment-proof" className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary" style={{width: '140px'}}>
                {imageUrl ? 'Ubah file' : 'Upload'}
              </label>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 text-right">
              {_.get(fileImage, 'name') &&
                <Button variant="contained" disableElevation disableRipple>
                  {fileImage.name.substring(0,20)}{fileImage.name.length > 20 && '...'}
                  &nbsp;&nbsp;&nbsp;
                  <Close onClick={() => removeImage()} />
                </Button>
              }
            </div>
          </div>
        </DialogContent>
      :     
        <DialogContent className="pl-3 pr-3">
          { !banksLoading ?
          <div>
            <div className="s-16 bolder mt-3 ml-3">Transfer Bank</div>
            <List>
              { paymentType !== 'xendit' ? 
                <ListItem button onClick={() => setStep(1)}>
                  <ListItemIcon className="mr-4">
                    {bank_id === 181 ?
                      <img src={bca} alt="bca" style={{width: '70px'}} />
                    : bank_id === 42 ?
                      <img src={mandiri} alt="mandiri" style={{width: '70px'}} />
                    : bank_id === 41 ?
                      <img src={bri} alt="bri" style={{width: '70px'}} />
                    : bank_id === 43 ?
                      <img src={bni} alt="bni" style={{width: '70px'}} />
                    :
                      <img src={other} alt="other" />
                    }
                  </ListItemIcon>
                  {bank_id === 181 ?
                    <ListItemText primary="Transfer Bank BCA" />
                  : bank_id === 42 ?
                    <ListItemText primary="Transfer Bank Mandiri" />
                  : bank_id === 41 ?
                    <ListItemText primary="Transfer Bank BRI" />
                  : bank_id === 43 ?
                    <ListItemText primary="Transfer Bank BNI" />
                  :
                    <ListItemText primary="Transfer Bank Lain" />
                  }
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItem>
                : ''
              }
              { paymentType === 'xendit' && banks.map((bank, index) => (
                  <ListItem button onClick={() => onSelectBank(bank.key)} key={index}>
                    <ListItemIcon className="mr-4">
                      <img src={bank.icon} alt={bank.key} style={{width: '70px'}} />
                    </ListItemIcon>
                    <ListItemText primary={bank.name} secondary={ bank.admin_fee ? 'Admin Fee : ' + formatRupiah(bank.admin_fee) : '' }/>
                    <ListItemSecondaryAction>
                      <ChevronRight />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
              {/* <Divider className="mt-2 mb-2" /> */}
              {/* <ListItem button onClick={handleMidtrans}>
                <ListItemIcon className="mr-4">
                  <img src={midtrans} alt="midtrans" style={{width: '70px'}} />
                </ListItemIcon>
                <ListItemText primary="Midtrans" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItem> */}
            </List>
          </div>
          : 
            <div style={{
              paddingTop: '5%',
              paddingBottom: '5%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <CircularProgress  size={48} /> 
            </div>
          }
        </DialogContent>
      }

      {step !== 0 &&
        <DialogActions className="border-top p-3">
          {step === 1 ?
            <Button 
              variant="outlined" 
              color="primary" 
              className="w-100" 
              onClick={() => setStep(2)}
            >Saya sudah bayar</Button>
          : step === 2 &&
            <div>
              <Button 
                variant="outlined" 
                onClick={() => onCloseModal()}
                style={{width: '120px'}}
              >Batal</Button>
              <Button 
                variant="contained" 
                color="primary" 
                className="ml-3"
                style={{width: '120px'}}
                disabled={!imageUrl}
                onClick={uploadPaymentProof}
              >Simpan</Button>
            </div>
          }
        </DialogActions>
      }

      <Snackbar 
        open={showNotification} 
        autoHideDuration={3000} 
        onClose={hideNotification}
        anchorOrigin={{ 
          vertical: 'top', 
          horizontal: 'center'
        }}
      >
        <Alert 
          onClose={hideNotification} 
          severity={_.get(notification, 'type', 'error')} 
          elevation={6} 
          variant="filled"
          className="s-14"
        >
          {_.get(notification, 'text', '')}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const config = state.config;
  const { listOfBanks, banksLoading } = state.virtualaccount.banks;
  const { vaNumber, vaNumberLoading} = state.virtualaccount.vaNumber;
  
  return {
    config,
    listOfBanks,
    banksLoading,
    vaNumber,
    vaNumberLoading
  }
};



const mapDispatchToProps = dispatch => {
  return {
    generatePolicy: (data) => dispatch(generatePolicy(data)),
    uploadPayment: (id, data) => dispatch(paymentProof(id, data)),
    payMidtrans: (id, data) => dispatch(payMidtrans(id, data)),
    getBanks: (type) => dispatch(getBanks(type)),
    getVaNumber: (type, params) => dispatch(getVaNumber(type, params)),
    resetVaNumber: () => dispatch(resetVaNumber()),
    getConfig: (params) => dispatch(getConfig(params)),
    paymentProofTopupPlan: (id, data) => dispatch(paymentProofTopupPlan(id, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModal);