export const GET_CONFIG = 'GET_CONFIG';

export const getConfig = (params) => {
  return {
    type: GET_CONFIG,
    payload: {
      request: {
        url: `/config`,
        method: 'get',
        params
      }
    }
  }
}