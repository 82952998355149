import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import './Login.scss';
import logo from '../../assets/ritase-logo.svg';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const Login = (props) => {
  const currentPage = props.location.pathname.substring(1);
  useEffect(() => {
    if (props.token) {
      props.history.push('/dashboard');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={0} className="login">
      <Grid item sm={9} className="login-illustration"></Grid>
      <Grid item sm={3} xs={12} className="login-container">
        <div>
          <img src={logo} alt="logo" className="login-logo mb-4" />
          <div className="s-22 text-grey bolder">Supply Chain Financing</div>
        </div>
        
        {currentPage === 'forgot' ?
          <ForgotPassword />
        : currentPage === 'reset' ?
          <ResetPassword />
        :
          <LoginForm />
        }

        <div className="login-footer">
          &copy; 2020 PT Digital Truk Indonesia. All Rights Reserved.
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  }
}

export default connect(mapStateToProps)(Login);