 import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, InputAdornment, Input, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, List, ListItem, ListItemText, ClickAwayListener, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { ExpandMore, Search, Close, Check } from '@material-ui/icons'; 
import _ from 'lodash';
import moment from 'moment';
import Page from '../Page';
import { formatRupiah, getCountdown, tdsSingkat } from '../../lib/utils';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import PaymentMethodModal from './PaymentMethodModal';
import './InvoiceList.scss';
import { getLendList, exportExcel } from '../../actions/scf';
import Pagination from '../../components/Pagination';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Loading from '../../components/Loading';

const InvoiceList = (props) => {
  const { is_fetching, list, meta } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [paymentItem, setPaymentItem] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [anchorShipper, setAnchorShipper] = useState(null);
  const [searchShipper, setSearchShipper] = useState('');
  const [selectedShipper, setSelectedShipper] = useState(null);
  const openShipper = Boolean(anchorShipper);
  const idShipper = openShipper ? 'simple-popper' : undefined;
  const [sort, setSort] = useState('');
  const [anchorSort, setAnchorSort] = useState(null);
  const openSort = Boolean(anchorSort);
  const idSort = openSort ? 'simple-popper' : undefined;
  const sortValues = [
    { value: 'request_amount,DESC', label: 'Jumlah Invoice Terbesar' },
    { value: 'request_amount,ASC', label: 'Jumlah Invoice Terkecil' },
    { value: 'scf_borrow_requests.created_at,DESC', label: 'Tanggal Invoice' },
  ];
  const statuses = [
    { id: 'booked', value: 'Booked', color: 'black' },
    { id: 'approved', value: 'Approved', color: 'blue' },
    { id: 'paid', value: 'Paid', color: 'yellow' },
    // { id: 'disbursed', value: 'Disbursed', color: 'green' },
    { id: 'repaid', value: 'Re-Paid', color: 'green' },
    { id: 'overdue', value: 'Overdue', color: 'red' },
    { id: 'canceled', value: 'Reject', color: 'red' },
    { id: 'cancel', value: 'Cancel', color: 'red' },
    { id: 'reject', value: 'Reject', color: 'red' },
  ];
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [params, setParams] = useState({});
  const shippers = [
    {
      name: 'Ritase',
      id: 'ritase'
    },
  ];
  const [exportStatus, setExportStatus] = useState(0);
  const [downloaded, setDownloaded] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const batchDownloadSize = 50;
  const exportStatusString = [
    '',                 // 0
    'Fetching data',    // 1
    'Building file',    // 2
    'Ready'             // 3
  ];

  useEffect(() => {
    props.getList();
    // eslint-disable-next-line
  }, []);

  const toggleStatus = (item) => {
    setSelectedStatus(item);

    let new_params = {...params};
    if (item !== 'all') {
      new_params.status = item === 'paid' ? 'disbursed' : item;
    } else {
      delete new_params.status;
    };

    setParams(new_params);
    props.getList(0, new_params);
  }

  const selectShipper = (item) => {
    setAnchorShipper(null);
    setSelectedShipper(item);
    setSearchShipper('');
    let new_params = {...params};
    if (item) {
      new_params.shipper_target = item.id;
    } else {
      delete new_params.shipper_target;
    }
    setParams(new_params);
    props.getList(0, new_params);
  }

  const filterShipper = () => {
    let filtered_shippers = shippers;
    if (searchShipper.length > 0) {
      filtered_shippers = _.filter(shippers, company => _.includes(company.name.toLowerCase(), searchShipper));
    }
    return filtered_shippers;
  }

  const resetFilter = () => {
    setSelectedShipper(null);
    setInvoiceDate('');
    setDueDate('');
    delete params.shipper_target;
    delete params.invoice_date;
    delete params.repayment_due_date;
    props.getList(0, params);
  }

  const onDateChange = (type, date) => {
    let new_params = {...params};
    switch (type) {
      case 'invoice_date':
        setInvoiceDate(date);
        break;
      case 'repayment_due_date':
        setDueDate(date);
        break;
      default:
        break;
    }

    if (date) {
      let formatted_date = moment(date).format('YYYY-MM-DD');
      new_params[type] = formatted_date;
    } else {
      delete new_params[type];
    };

    setParams(new_params);
    props.getList(0, new_params);
  }

  const onChangeSort = value => {
    setAnchorSort(null);
    setSort(value);
    let split_value = value.split(',');
    let new_params = {...params};
    new_params.sort = split_value[0];
    new_params.asc = split_value[1];
    setParams(new_params);
    props.getList(0, new_params);
  }

  // const calculateCount = item => {
  //   let items = _.get(item, 'borrower_request.invoice.items', []);
  //   let orders = items.length;
  //   let trip_ids = items.map(item => _.get(item, 'order.trip_id'));
  //   let trips = _.chain(trip_ids)
  //                .filter(trip => !_.isNil(trip))
  //                .uniq()
  //                .value()
  //                .length;

  //   return { trips, orders };
  // }

  const openPaymentModal = (item) => {
    setPaymentItem(item);
    setOpenPaymentMethod(true);
  }

  const getFileName = (string_url) => {
    // eslint-disable-next-line
    var str = String(string_url).replace(/^.*[\\\/]/, '');
    if (str.length > 25) {
      return str.substr(0, 10) + '...' + str.substr(str.length - 10, str.length);
    }
    return str;
  }

  const s2ab = (s) => { 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
  }

  const startExportExcel = () => {
    setExportStatus(1);
    let data = [];
  
    getBatchExportData(params, 0, batchDownloadSize, data);
  };

  const getBatchExportData = (param, offset, limit, data) => {
    setIsDownloading(true);
    props.getExportData(offset, param, limit).then(results => {
      if (_.get(results, 'payload.data.errorMessage')) {
        setIsDownloading(false);
        Swal.fire('Error!', results.payload.data.errorMessage, 'error');
      } else {
        let result = _.get(results, 'payload.data.rows', []);
        setDownloaded(downloaded + result.length);
        data = _.concat(data, result);
        if (result.length < limit) {
          setExportStatus(exportStatus + 1);
          processExport(data);
          return;
        } else {
          getBatchExportData(param, offset+limit, limit, data);
        }
      };
    })
  };

  const processExport = (result) => {
    if (result.length === 0) {
      Swal.fire('', 'Tidak ada data', 'error');
      setIsDownloading(false);
      return;
    }

    let data = [[
      'No.',
      'No. Invoice Transporter',
      'No. Invoice SCF',
      'Shipper',
      'Transporter',
      'Tanggal Target Pencairan',
      'TOP yang dipilih',
      'Status',
      'Tanggal Repayment',
    ]];
    
    for(let i = 0; i < result.length; i++){
      let disbursement_date = _.get(result, `[${i}].borrower_request.disbursement_date`, false);
      let repayment_date = _.get(result, `[${i}].repayment_date.created_at`, false);
      data.push([
        i + 1,
        _.get(result, `[${i}].borrower_request.invoice_id`, ''),
        _.get(result, `[${i}].id`, ''),
        _.get(result, `[${i}].borrower_request.invoice.items[0].order.company_shipper.name`, ''),
        _.get(result, `[${i}].borrower_request.company_borrower_id.name`, ''),
        disbursement_date ? moment(disbursement_date).format('ddd, DD MMM YYYY') : '',
        _.get(result, `[${i}].borrower_request.scf_top`, ''),
        _.get(result, `[${i}].status`, ''),
        repayment_date ? moment(repayment_date).format('ddd, DD MMM YYYY') : '',
      ]);
    }

    /* create a new blank workbook */
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet(data);
    
    /* add worksheet to workbook */
    let ws_name = "List Shipper";
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    /* save workbook */
    let fileName = `Ritase_My_Invoice_${moment().format('YYYY-MM-DD')}.xlsx`;
    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), fileName);
    
    setIsDownloading(false);
    setExportStatus(0);
  }

  const getDefaultImage = (text) => {
    return `https://ui-avatars.com/api/?background=D4EDEA&color=27A397&name=${text.replace(/ /g, '+').replace(/@/g, '+')}` 
  }

  return (
    <Page title="My Invoice">
      <Loading open={isDownloading} />
      <div className="container-content">
        <div className="row">
          <div className="col-8">
            <div className="s-24 bolder">Daftar Invoice yang Telah Didanai</div>
          </div>
          <div className="col-4 text-right">
            <Button 
              variant="outlined" 
              color="primary" 
              disableElevation 
              onClick={startExportExcel}
              disabled={isDownloading}
            >
              {isDownloading ? exportStatusString[exportStatus] : 'Export Excel'}
            </Button>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-10 flex pl-2">
            <div className="mr-3">
              <ClickAwayListener onClickAway={() => setAnchorShipper(null)}>
                <div>
                  <Button 
                    onClick={(e) => setAnchorShipper(anchorShipper ? null : e.currentTarget)} 
                    aria-describedby={idShipper}
                  >
                    Shipper&nbsp;&nbsp;
                    <ExpandMore />
                  </Button>
                  {openShipper ? (
                    <Popper 
                      id={idShipper}
                      open={openShipper}
                      anchorEl={anchorShipper}
                      placement="bottom-start"
                    >
                      <Paper className="p-3">
                        <Input
                          className="w-100"
                          placeholder="Search..."
                          startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
                          value={searchShipper}
                          onChange={(e) => setSearchShipper(e.target.value)}
                        />
                        {filterShipper().length > 0 ?
                          <List className="p-0">
                            {filterShipper().map((company) => (
                              <ListItem 
                                button 
                                key={company.id} 
                                onClick={() => selectShipper(company)}
                              >
                                <ListItemText primary={company.name} />
                              </ListItem>
                            ))}
                          </List>
                        :
                          <div className="mt-2">No shipper found</div>
                        }
                      </Paper>
                    </Popper>
                  ) : null}
                </div>
              </ClickAwayListener>
            </div>
            <div className="mr-3">
              <DatePicker
                selected={invoiceDate}
                onChange={date => onDateChange('invoice_date', date)}
                customInput={<Button>
                  Tanggal Pengajuan&nbsp;&nbsp;
                  <ExpandMore />
                </Button>}
              />
            </div>
            <div className="mr-3">
              <DatePicker
                selected={dueDate}
                onChange={date => onDateChange('repayment_due_date', date)}
                customInput={<Button>
                  Tanggal Pengembalian Dana&nbsp;&nbsp;
                  <ExpandMore />
                </Button>}
              />
            </div>
          </div>
          <div className="col-2 text-right">
            <ClickAwayListener onClickAway={() => setAnchorSort(null)}>
              <div>
                <Button 
                  onClick={(e) => setAnchorSort(anchorSort ? null : e.currentTarget)} 
                  aria-describedby={idSort}
                >
                  Urutkan&nbsp;&nbsp;
                  {sort && <span><Check color="primary" />&nbsp;&nbsp;</span>}
                  <ExpandMore />
                </Button>
                {openSort ? (
                  <Popper 
                    id={idSort}
                    open={openSort}
                    anchorEl={anchorSort}
                    placement="bottom-end"
                  >
                    <Paper className="p-3">
                      <div className="mb-2">Urutkan berdasarkan :</div>
                      <RadioGroup 
                        aria-label="sort" 
                        name="sort" 
                        value={sort} 
                        onChange={(e) => onChangeSort(e.target.value)}
                      >
                        {sortValues.map(item => (
                          <FormControlLabel 
                            value={item.value} 
                            control={<Radio color="primary" className="pl-0" />} 
                            label={item.label} 
                            className="m-0"
                            key={item.value}
                          />
                        ))}
                      </RadioGroup>
                    </Paper>
                  </Popper>
                ) : null}
              </div>
            </ClickAwayListener>
          </div>
        </div>

        {(!_.isNil(selectedShipper) || invoiceDate || dueDate) &&
          <div className="row">
            <div className="col-12">
              <div className="filter-container">
                <div className="filter-list">
                  {!_.isNil(selectedShipper) &&
                    <div className="filter-item">
                      {selectedShipper.name}
                      &nbsp;&nbsp;&nbsp;
                      <Close onClick={() => selectShipper(null)} />
                    </div>
                  }
                  {invoiceDate &&
                    <div className="filter-item">
                      Tanggal Pengajuan: {moment(invoiceDate).format('DD MMM YYYY')}
                      &nbsp;&nbsp;&nbsp;
                      <Close onClick={() => onDateChange('invoice_date', '')} />
                    </div>
                  }
                  {dueDate &&
                    <div className="filter-item">
                      Tanggal Pengembalian Dana: {moment(dueDate).format('DD MMM YYYY')}
                      &nbsp;&nbsp;&nbsp;
                      <Close onClick={() => onDateChange('repayment_due_date', '')} />
                    </div>
                  }
                </div>
                <div className="text-ritase pointer" onClick={() => resetFilter()}>Clear All</div>
              </div>
            </div>
          </div>
        }

        <div className="row mt-3 mb-2">
          <div className="col-12 flex align-items-center" style={{whiteSpace: 'nowrap'}}>
            <div className="s-18 bolder mr-3">Filter Status</div>
            <div className="filter-list">
              <div 
                className="filter-item pointer" 
                onClick={() => toggleStatus('all')}
                style={{padding: '8px 22px'}}
                aria-selected={selectedStatus === 'all'} 
              >
                Semua
              </div>
              {statuses.map(status => {
                const isSelected = selectedStatus === status.id;
                if (status.id === 'cancel' || status.id === 'reject') return null;

                return (
                  <div 
                    className="filter-item pointer" 
                    aria-selected={isSelected} 
                    onClick={() => toggleStatus(status.id)}
                    style={{padding: '8px 22px'}}
                    key={status.id}
                  >
                    {status.value}
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12" style={{whiteSpace: 'nowrap'}}>
            <TableContainer className="table-card">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nomor Invoice</TableCell>
                    <TableCell>Shipper</TableCell>
                    <TableCell>Transporter</TableCell>
                    {/* <TableCell>Jumlah Trip</TableCell> */}
                    {selectedStatus !== 'repaid' && <TableCell>Tanggal Pengajuan</TableCell>}
                    {selectedStatus === 'repaid' && <TableCell>Tanggal Pengembalian</TableCell>}
                    {selectedStatus === 'repaid' && <TableCell>Bukti Pengembalian</TableCell>}
                    {(selectedStatus === 'disbursed' || selectedStatus === 'overdue') && <TableCell>Batas Pengembalian</TableCell>}
                    {(selectedStatus === 'all' || selectedStatus === 'approved') && <TableCell>Tanggal Permintaan Pencairan</TableCell>}
                    {(selectedStatus === 'all' || selectedStatus === 'paid') && <TableCell>Tanggal Pembayaran</TableCell>}
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {is_fetching ?
                    <TableRow>
                      <TableCell align="center" colSpan={7} className="border-0">
                        Loading
                      </TableCell>
                    </TableRow>
                  : list.length > 0 ?
                    list.map((item, i) => {
                      // const counts = calculateCount(item);
                      let status_color = _
                          .chain(statuses)
                          .find(status => status.id === _.get(item, 'status', '').toLowerCase())
                          .get('color', 'blue')
                          .value();
                      if (item.status === 'approved' && _.get(item, 'proof.length', 0) > 0) {
                        item.status = 'waiting for verification'
                      }
                      const repayment_countdown = getCountdown(item.repayment_due_date);
                      const disburse_countdown = _.get(item, 'borrower_request.disbursement_date') ? getCountdown(item.borrower_request.disbursement_date) : '';
                      item.isCompanyOrder = _.get(item, 'borrower_request.invoice.target') === 'company_order'
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            <div>
                              {_.get(item, 'borrower_request.invoice.id', false) ?
                                tdsSingkat(item.borrower_request.invoice.id)
                              :
                                '-'
                              }
                            </div>
                            <div className="mt-3">
                              {_.get(item, 'id', false) ?
                                'SCF : ' + tdsSingkat(item.id)
                              :
                                '-'
                              }
                            </div>
                            <Link to={{pathname: `/invoice/${item.id}`, state: { prevPath: 'invoice' }}} className="mt-4 text-ritase pointer block">Detail Invoice</Link>
                          </TableCell>
                          <TableCell>
                            <div className="flex align-items-top">
                              {_.get(item, 'borrower_request.invoice.items[0].order.company_shipper.image_url', false) ?
                                <img src={item.borrower_request.invoice.items[0].order.company_shipper.image_url} alt="" className="shipper_initial" />

                              :
                                item.isCompanyOrder ?
                                <img src={getDefaultImage( _.get(item, 'borrower_request.invoice.items[0].order.transporter_shipper.name', ''))} alt="" className="shipper_initial" />
                                :
                                <img src={getDefaultImage( _.get(item, 'borrower_request.invoice.items[0].order.company_shipper.name', ''))} alt="" className="shipper_initial" />
                                // <div className="shipper_initial">
                                //   { _.get(item, 'borrower_request.invoice.items[0].order.company_shipper.name', '').substr(0,1) }
                                // </div>
                              }
                              {
                                item.isCompanyOrder ?
                                <div>
                                  <div className="bolder">{ _.get(item, 'borrower_request.invoice.items[0].order.transporter_shipper.name', '-') }</div>
                                  <div className="text-grey">Ritase</div>
                                </div>
                                :
                                
                                <div>
                                  <div className="bolder">{ _.get(item, 'borrower_request.invoice.items[0].order.company_shipper.name', '-') }</div>
                                  <div className="text-grey">{_.capitalize(_.get(item, 'borrower_request.invoice.target', '-'))}</div>
                                </div>
                              }
                            </div>
                          </TableCell>
                          <TableCell>{_.get(item, 'borrower_request.company_borrower_id.name', '-')}</TableCell>
                          {/* <TableCell>
                            <div>{counts.trips} Trip</div>
                            {counts.orders > 0 &&
                              <div className="s-12 mt-2">
                                <span className="text-warning"><FiberManualRecord fontSize="small" /></span>
                                <span className="text-grey">&nbsp;{counts.orders} Order</span>
                              </div>
                            }
                          </TableCell> */}
                          {selectedStatus !== 'repaid' &&
                            <TableCell>
                              {_.get(item, 'borrower_request.created_at', false) ?
                                moment(item.borrower_request.created_at).format('ddd, DD MMM YYYY')
                              :
                                '-'
                              }
                            </TableCell>
                          }
                          {selectedStatus === 'repaid' &&
                            <TableCell>
                              {_.get(item, 'repayment_date.created_at', false) ?
                                moment(item.repayment_date.created_at).format('ddd, DD MMM YYYY')
                              :
                                '-'
                              }
                            </TableCell>
                          }
                          {selectedStatus === 'repaid' &&
                            <TableCell>
                              {_.get(item, 'borrower_request.proof[0].image_url', false) ?
                                <div className="pointer text-ritase" onClick={() => setImageUrl(item.borrower_request.proof[0].image_url)}>{getFileName(item.borrower_request.proof[0].image_url)}</div>
                              :
                                '-'
                              }
                            </TableCell>
                          }
                          {(selectedStatus === 'disbursed' || selectedStatus === 'overdue') &&
                            <TableCell>
                            {_.get(item, 'repayment_due_date', false) ?
                              <div>
                                <div>{moment(item.repayment_due_date).format('ddd, DD MMM YYYY')}</div>
                                <div className={repayment_countdown > 0 ? "text-danger bolder" : repayment_countdown > -5 ? "text-danger" : repayment_countdown <= -60 ? "text-ritase" : "text-grey"}>
                                  ( {repayment_countdown > 0 ? 'Overdue' : `${Math.abs(repayment_countdown)} hari lagi`} )
                                </div>
                              </div>
                            :
                              '-'
                            }
                            </TableCell>
                          }
                          {(selectedStatus === 'all' || selectedStatus === 'approved') &&
                            <TableCell>
                            {_.get(item, 'borrower_request.disbursement_date', false) ?
                              <div>
                                <div>{moment(item.borrower_request.disbursement_date).format('ddd, DD MMM YYYY')}</div>
                                {['booked', 'approved'].includes(item.status) &&
                                  <div className={disburse_countdown > 0 ? "text-danger bolder" : disburse_countdown > -5 ? "text-danger" : disburse_countdown <= -60 ? "text-ritase" : "text-grey"}>
                                    ( {disburse_countdown > 0 ? 'Overdue' : `${Math.abs(disburse_countdown)} hari lagi`} )
                                  </div>
                                }
                              </div>
                            : _.get(item, 'borrower_request.scf_top', false) ?
                              `H + ${item.borrower_request.scf_top}`
                            :
                              '-'
                            }
                            </TableCell>
                          }
                          {(selectedStatus === 'all' || selectedStatus === 'paid') &&
                            <TableCell>
                            {_.get(item, 'verify_paid_date.created_at', false) && ['paid', 'repaid'].includes(item.status) ?
                              <div>
                                <div>{moment(item.verify_paid_date.created_at).format('ddd, DD MMM YYYY')}</div>
                              </div>
                            :
                              '-'
                            }
                            </TableCell>
                          }
                          <TableCell>
                            {_.get(item, 'status', false) ?
                              <div>
                                <div className={`status-badge ${status_color}`} style={{maxWidth: '100px', whiteSpace: 'normal'}}>{_.capitalize(item.status)}</div>
                                {(item.status === 'approved' && _.get(item, 'topup_plan[0].status') === 'created') &&
                                  <Tooltip
                                    title={`Topup plan ${_.get(item, 'topup_plan[0].id')}`}
                                    arrow
                                    classes={{
                                      tooltip: 's-12 mt-1'
                                    }}
                                  >
                                    <div className="s-12 text-info mt-1 pointer">
                                      Menunggu pembayaran
                                    </div>
                                  </Tooltip>
                                }
                              </div>
                            :
                              '-'
                            }
                          </TableCell>
                          <TableCell>
                            <div className="text-action s-18 bolder">
                              {formatRupiah(_.get(item, 'borrower_request.request_amount', 0))}
                            </div>
                            {_.get(item, 'status', '').toLowerCase() === 'approved' &&
                              <div>
                                <Button 
                                  variant="contained" 
                                  color="secondary" 
                                  disableElevation
                                  className="mt-3 w-100"
                                  onClick={() => openPaymentModal(item)}
                                >Bayar</Button>
                              </div>
                            }
                            {_.get(item, 'status', '').toLowerCase() === 'repaid' &&
                              <div className="mt-3">
                                <div>
                                  Total Pengembalian: <strong>{formatRupiah(_.get(item, 'total_amount_repaid', 0))}</strong>
                                </div>
                                <div>
                                  Rate: {!_.isNil(_.get(item, 'repayment_rate')) ? `${item.repayment_rate}%` : '-'}
                                </div>
                              </div>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })
                  :
                    <TableRow>
                      <TableCell align="center" colSpan={7} className="border-0">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      
        <Pagination 
          count={meta.count || 0}
          limit={meta.limit || 10}
          offset={meta.offset || 0}
          onChange={(e, page) => props.getList(((page - 1) * 10), params)}
        />
      </div>

      {openPaymentMethod &&
        <PaymentMethodModal
          open={openPaymentMethod}
          closeModal={() => setOpenPaymentMethod(false)}
          item={paymentItem}
          onFinish={() => props.getList(meta.offset, params)}
          paymentType="manual"
        />
      }

      {Boolean(imageUrl) &&
        <Dialog
          open={Boolean(imageUrl)}
          onClose={() => setImageUrl(null)}
          maxWidth="lg"
          fullWidth
          scroll="paper"
        >
          <DialogTitle onClose={() => setImageUrl(null)} className="border-bottom">
            Bukti Pengembalian
            <Close className="pointer pull-right" onClick={() => setImageUrl(null)} />
          </DialogTitle>
          <DialogContent>
            <img className="w-100" src={imageUrl} alt={imageUrl} />
          </DialogContent>
        </Dialog>
      }
    </Page>
  );
};

const mapStateToProps = state => {
  let { is_fetching, list, meta } = state.invoice;
  return {
    is_fetching, list, meta
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getList: (offset, params) => dispatch(getLendList(offset, params)),
    getExportData: (offset, params, limit) => dispatch(exportExcel(offset, params, limit))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);