import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import "react-image-gallery/styles/css/image-gallery.css"
import './App.css';
import store from './store';
import Routes from './route.js';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#27a397',
    },
    secondary: {
      main: '#f16b27',
    },
    success: {
      main: '#28a94a',
    },
    info: {
      main: '#009abf',
    },
    warning: {
      main: '#ffbe00',
    },
    error: {
      main: '#f23d3d',
    },
  },
  typography: {
    fontFamily: 'Avenir',
    button: {
      textTransform: 'none',
      fontFamily: 'Avenir',
      fontSize: '14px'
    },
  },
  overrides: {
    MuiButton: {
      outlined: {
        borderColor: '#404040'
      }
    }
  }
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    )
  }
};

export default App;