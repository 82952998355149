import axiosMiddleware from 'redux-axios-middleware'
import axios from 'axios'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { handleTimeout } from './actions/auth'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
});

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch(err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch(err) {
    console.log(err);
  }
};

const middlewareConfig = {
  interceptors: {
    request: [
      ({getState, dispatch, getSourceAction}, req) => {
        if (getState().auth.token) {
          req.headers['Authorization'] = getState().auth.token
        }
        return req;
      }
    ]
  },
  onError: ({action, error, next, dispatch}) => {
    if (error.response.status === 401) {
      dispatch(handleTimeout());
      window.location.href = '/login'
    }

    let nextAction = {
      error,
      meta: {
        previousAction: action,
      },
    };
    if (action.types && action.types.length === 3) {
      nextAction.type = action.types[2];
    } else {
      nextAction.type = action.type + '_FAIL';
    }
    next(nextAction);
    return nextAction;
  }
};

const persistedState = loadState()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers, 
  persistedState, 
  composeEnhancers(
    applyMiddleware(thunk, axiosMiddleware(client, middlewareConfig))
  )
);

store.subscribe(() => {
	saveState(store.getState())
})

export default store
