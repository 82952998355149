import _ from 'lodash';
import Swal from 'sweetalert2';
import { generatePolicy } from '../actions/upload';
import Axios from 'axios';
import store from '../store';
import moment from 'moment';

/**
 * format rupiah with dot thousand separator, e.g. "Rp 1.000.000"
 */
export const formatRupiah = function (x) {
  x = Math.floor(x);  // remove decimal
  // use dot as thousand separator
  // regex from here: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  let formatted = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return "Rp " + formatted;
};

export const defaultMeta = {
  count: 0,
  limit: 10, 
  offset: 0
};

export const getLevel = function(badge_level) {
  if (!badge_level) return 'no_badge';
  
  let validLevel = ['no_badge', 'bronze', 'silver', 'gold', 'diamond'];
  let level = _.get(badge_level, 'badge_level', '').replace(' ', '_');
  if (!level || !validLevel.includes(level.toLowerCase())) return 'no_badge';

  return level.toLowerCase();
}

// global function to get company sublevel
export const getSublevel = function(badge_level) {
  if (!badge_level) return 1;
  
  let validSublevel = [1, 2, 3, 4, 5];
  let sublevel = _.get(badge_level, 'badge_sub_level');
  if (!sublevel || !validSublevel.includes(sublevel)) return 1;

  return sublevel;
}

export const tdsSingkat = (str) => {
  let tdsArr = str.split('-');
  tdsArr[2] = tdsArr[2].substr(-6);
  return tdsArr.join('-');
}

export const ISODateString = (d) => {
  function pad(n) {return n<10 ? '0'+n : n}
  return '' + d.getUTCFullYear()
    + pad(d.getUTCMonth()+1)
    + pad(d.getUTCDate())+'T'
    + '000000Z'
}

export const uploader = (file, callback, folderName = 'users') => {
  const extension = file.name.substr(file.name.lastIndexOf('.')+1);
  const date = new Date();
  const body = {
    currentDate: date.toISOString(),
    folderName: `${folderName}/`,
    redirectURL: "",
    app: "ritase"
  }
  
  store.dispatch(generatePolicy(body)).then(result => {
    if (result.type === 'GENERATE_POLICY_FAIL') {
      Swal.fire('Error!', result.error.message, 'error');
    } else if (_.get(result, 'payload.data.errorMessage')) {
      Swal.fire('Error!', result.payload.data.errorMessage, 'error');
    } else {
      var isoDate = ISODateString(date);
      let res = result.payload.data;

      var bodyFormData = new FormData();
      bodyFormData.append('key', "users/" + res.imageName + "." + extension);
      bodyFormData.append('acl', "public-read");
      bodyFormData.append('Content-Type', file.type);
      bodyFormData.append('x-amz-meta-uuid', "14365123651274");
      bodyFormData.append('X-Amz-Credential', process.env.REACT_APP_S3_ACCESSKEY + '/' + isoDate.split('T')[0] + '/ap-southeast-1/s3/aws4_request')
      bodyFormData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256')
      bodyFormData.append('X-Amz-Date', isoDate)
      bodyFormData.append('x-amz-meta-tag', '')
      bodyFormData.append('success_action_status', '200')
      bodyFormData.append('Policy', res.data.s3Policy)
      bodyFormData.append('X-Amz-Signature', res.data.s3Signature)
      bodyFormData.append('file', file)
      
      Axios({
        method: 'post',
        url: process.env.REACT_APP_S3_PATH,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        //handle success
        const file_url = `${process.env.REACT_APP_S3_PATH}users/${res.imageName}.${extension}`
        if (callback) {
          callback(response, file_url)
        }
      }).catch(response => {
        //handle error
        Swal.fire('Error!', 'Upload foto gagal', 'error');
      });
    };
  })
}

export const getFileName = (string_url) => {
  // eslint-disable-next-line
  var str = String(string_url).replace(/^.*[\\\/]/, '');
  if (str.length > 25) {
    return str.substr(0, 10) + '...' + str.substr(str.length - 10, str.length);
  }
  return str;
}

export const getCountdown = (date) => {
  let now = moment().startOf('day');
  let due = moment(date).startOf('day');
  return moment(now).diff(due, 'days');
};