import { LOGIN, LOGOUT, UPDATE_ME, USER_TIMEOUT, ME } from "../actions/auth";
import _ from 'lodash';

const initState = {
  user: null,
  token: null
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case LOGIN + '_SUCCESS':
      return {
        user: _.get(action, 'payload.data.user', null),
        token: _.get(action, 'payload.data.token', null),
      }
    case UPDATE_ME:
      let new_user = {...state.user};
      let data = _.get(action, 'payload.data', {});
      if (_.get(data, 'image_url', false)) {
        new_user.image_url = action.payload.data.image_url;
      }

      if (_.get(data, 'name', false)) {
        new_user.name = action.payload.data.name;
      }

      return {
        ...state,
        user: new_user
      }
    case ME + '_SUCCESS':
      let user_me = {...state.user};
      user_me.company = _.get(action, 'payload.data.company', null);
      user_me.is_scf_dp = JSON.parse(_.get(user_me, 'company.config.settings', '{"scf_dp": false}')).scf_dp || false;
      return {
        ...state,
        user: user_me,
      }
    case LOGIN + '_FAIL':
    case LOGOUT + '_FAIL':
    case LOGOUT + '_SUCCESS':
    case USER_TIMEOUT:
      return initState;
    default:
      return state;
  }
}

export default auth;