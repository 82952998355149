import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { forgotPassword } from '../../actions/auth';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let body = {
      email
    }

    props.forgotPassword(body).then(result => {
      setIsLoading(false);
      if (result.type === 'FORGOT_PASSWORD_FAIL') {
        Swal.fire('Error!', 'Status: ' + result.payload.status, 'error');
      } else if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        Swal.fire({
          title: 'Pemulihan Password Dikirim',
          text: 'Kami telah mengirimkan prosedur pemulihan password ke email anda. Silahkan cek email Anda.',
          icon: 'success',
          confirmButtonColor: '#27a397'
        });
      }
    });
  }

  return (
    <div className="mt-4 mb-4">
      <div className="s-18 bolder">LUPA PASSWORD</div>
      <div className="text-grey mt-1">Lupa password? Masukkan e-mail Anda, untuk membuat password baru.</div>
      <form onSubmit={submitForm}>
        <div className="mt-3 form-group">
          <label htmlFor="email">E-mail</label>
          <input 
            name="email"
            type="email"
            placeholder="E-mail" 
            className="form-control"
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>

        <div className="text-right">
          <Button 
            type="submit" 
            color="primary" 
            disabled={!email || isLoading}
            variant="contained"
            className="mt-2"
            disableElevation
            style={{ width: '92px' }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'KIRIM'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
	return {
    forgotPassword: (data) => dispatch(forgotPassword(data)),
  }
}

export default connect(null, mapDispatchToProps)(withRouter(ForgotPassword));