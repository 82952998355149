import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { login, getMe } from '../../actions/auth';

const LoginForm = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let body = {
      email, password, user_type: 'admin'
    }

    props.login(body).then(result => {
      setIsLoading(false);
      if (result.type === 'LOGIN_FAIL') {
        Swal.fire('Error!', result.error.message, 'error');
      } else if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        props.getMe().then(result => {
          props.history.push('/dashboard');
        });
      }
    });
  }

  return (
    <div className="mt-4 mb-4">
      <div className="s-18 bolder">LOG IN</div>
      <form onSubmit={submitForm}>
        <div className="mt-3 form-group">
          <label htmlFor="email">E-mail</label>
          <input 
            type="email"
            placeholder="E-mail" 
            className="form-control"
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        
        <div className="mt-3 form-group">
          <label htmlFor="password">Password</label>
          <input 
            type="password"
            placeholder="Password" 
            className="form-control"
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>

        <div className="text-grey text-right pointer mt-1">
          <Link to='/forgot' className="text-grey">Lupa Password?</Link>
        </div>

        <Button 
          type="submit" 
          color="primary" 
          disabled={!email || !password || isLoading}
          variant="contained"
          className="mt-2"
          disableElevation
          style={{ width: '92px' }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'MASUK'}
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
	return {
    login: (data) => dispatch(login(data)),
    getMe: () => dispatch(getMe()),
  }
}

export default connect(null, mapDispatchToProps)(withRouter(LoginForm));