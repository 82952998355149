import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, InputAdornment, Input, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, List, ListItem, ListItemText, ClickAwayListener, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { ExpandMore, Search, Close, Check } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import './Dashboard.scss';
import { formatRupiah, getLevel, getSublevel, tdsSingkat } from '../../lib/utils';
import addbulk from '../../assets/icons/addbulk.svg';
import { getList } from '../../actions/scf';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import Pagination from '../../components/Pagination';

const DashboardInvoice = (props) => {
  const { is_fetching, list, meta, isBulk, showDocuments, setOpenModal, selected, setSelected, isSelected, toggleIsBulk } = props;
  const [invoiceDate, setInvoiceDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [disbursementDate, setDisbursementDate] = useState('');
  const [anchorShipper, setAnchorShipper] = useState(null);
  const [searchShipper, setSearchShipper] = useState('');
  const [selectedShipper, setSelectedShipper] = useState(null);
  const openShipper = Boolean(anchorShipper);
  const idShipper = openShipper ? 'simple-popper' : undefined;
  const [sort, setSort] = useState('');
  const [anchorSort, setAnchorSort] = useState(null);
  const openSort = Boolean(anchorSort);
  const idSort = openSort ? 'simple-popper' : undefined;
  const sortValues = [
    { value: 'request_amount,DESC', label: 'Jumlah Invoice Terbesar' },
    { value: 'request_amount,ASC', label: 'Jumlah Invoice Terkecil' },
    { value: 'scf_borrow_requests.created_at,DESC', label: 'Tanggal Invoice' },
  ];
  const [params, setParams] = useState({});
  const shippers = [
    {
      name: 'Ritase',
      id: 'ritase'
    },
  ];

  useEffect(() => {
    props.getList();
    // eslint-disable-next-line
  }, []);

  const handleClick = (event, item) => {
    const isSelected = _.find(selected, s => s.id === item.id, false);
    let newSelected = [...selected];

    if (isSelected) {
      newSelected = _.filter(selected, s => s.id !== item.id);
    } else {
      newSelected.push(item);
    };

    setSelected(newSelected);
  };

  const selectShipper = (item) => {
    setAnchorShipper(null);
    setSelectedShipper(item);
    setSearchShipper('');
    let new_params = {...params};
    if (item) {
      new_params.shipper_target = item.id;
    } else {
      delete new_params.shipper_target;
    }
    setParams(new_params);
    props.getList(0, new_params);
  }

  const filterShipper = () => {
    let filtered_shippers = shippers;
    if (searchShipper.length > 0) {
      filtered_shippers = _.filter(shippers, company => _.includes(company.name.toLowerCase(), searchShipper));
    }
    return filtered_shippers;
  }

  const resetFilter = () => {
    setSelectedShipper(null);
    setInvoiceDate('');
    setDueDate('');
    setDisbursementDate('');
    delete params.shipper_target;
    delete params.invoice_approved_at;
    delete params.repayment_due_date;
    delete params.disbursement_date;
    props.getList(0, params);
  }

  const onDateChange = (type, date) => {
    let new_params = {...params};
    switch (type) {
      case 'invoice_approved_at':
        setInvoiceDate(date);
        break;
      case 'repayment_due_date':
        setDueDate(date);
        break;
      case 'disbursement_date':
        setDisbursementDate(date);
        break;
      default:
        break;
    }

    if (date) {
      let formatted_date = moment(date).format('YYYY-MM-DD');
      new_params[type] = formatted_date;
    } else {
      delete new_params[type];
    };

    setParams(new_params);
    props.getList(0, new_params);
  }

  const onChangeSort = value => {
    setAnchorSort(null);
    setSort(value);
    let split_value = value.split(',');
    let new_params = {...params};
    new_params.sort = split_value[0];
    new_params.asc = split_value[1];
    setParams(new_params);
    props.getList(0, new_params);
  }

  const chooseInvoice = item => {
    setSelected([item]);
    setOpenModal(true);
  }

  const getDefaultImage = (text) => {
    return `https://ui-avatars.com/api/?background=D4EDEA&color=27A397&name=${text.replace(/ /g, '+').replace(/@/g, '+')}` 
  }
  
  return (
    <div>
      <div className="dashboard-content">
        <div className="container-content" style={{marginBottom: isBulk ? '100px' : '20px'}}>
          <div className="row align-items-center">
            <div className="col-6">
              <div className="s-24 bolder">Daftar Invoice</div>
            </div>
            <div className="col-6 text-right">
              {list.length > 0 && !isBulk && 
                <Button 
                  variant="outlined"
                  color="secondary" 
                  disableElevation
                  startIcon={<img src={addbulk} alt="add" />}
                  onClick={() => toggleIsBulk(true)}
                >
                  Danai Sekaligus
                </Button>
              }
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-10 flex">
              <div className="mr-3">
                <ClickAwayListener onClickAway={() => setAnchorShipper(null)}>
                  <div>
                    <Button 
                      onClick={(e) => setAnchorShipper(anchorShipper ? null : e.currentTarget)} 
                      aria-describedby={idShipper}
                    >
                      Shipper&nbsp;&nbsp;
                      <ExpandMore />
                    </Button>
                    {openShipper ? (
                      <Popper 
                        id={idShipper}
                        open={openShipper}
                        anchorEl={anchorShipper}
                        placement="bottom-start"
                      >
                        <Paper className="p-3">
                          <Input
                            className="w-100"
                            placeholder="Search..."
                            startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
                            value={searchShipper}
                            onChange={(e) => setSearchShipper(e.target.value)}
                          />
                          {filterShipper().length > 0 ?
                            <List className="p-0">
                              {filterShipper().map((company) => (
                                <ListItem 
                                  button 
                                  key={company.id} 
                                  onClick={() => selectShipper(company)}
                                >
                                  <ListItemText primary={company.name} />
                                </ListItem>
                              ))}
                            </List>
                          :
                            <div className="mt-2">No shipper found</div>
                          }
                        </Paper>
                      </Popper>
                    ) : null}
                  </div>
                </ClickAwayListener>
              </div>
              <div className="mr-3">
                <DatePicker
                  selected={invoiceDate}
                  onChange={date => onDateChange('invoice_approved_at', date)}
                  customInput={<Button>
                    Invoice Disetujui&nbsp;&nbsp;
                    <ExpandMore />
                  </Button>}
                />
              </div>
              <div className="mr-3">
                <DatePicker
                  selected={disbursementDate}
                  onChange={date => onDateChange('disbursement_date', date)}
                  customInput={<Button>
                    Permintaan Pencairan&nbsp;&nbsp;
                    <ExpandMore />
                  </Button>}
                />
              </div>
            </div>
            <div className="col-2 text-right">
              <ClickAwayListener onClickAway={() => setAnchorSort(null)}>
                <div>
                  <Button 
                    onClick={(e) => setAnchorSort(anchorSort ? null : e.currentTarget)} 
                    aria-describedby={idSort}
                  >
                    Urutkan&nbsp;&nbsp;
                    {sort && <span><Check color="primary" />&nbsp;&nbsp;</span>}
                    <ExpandMore />
                  </Button>
                  {openSort ? (
                    <Popper 
                      id={idSort}
                      open={openSort}
                      anchorEl={anchorSort}
                      placement="bottom-end"
                    >
                      <Paper className="p-3">
                        <div className="mb-2">Urutkan berdasarkan :</div>
                        <RadioGroup 
                          aria-label="sort" 
                          name="sort" 
                          value={sort} 
                          onChange={(e) => onChangeSort(e.target.value)}
                        >
                          {sortValues.map(item => (
                            <FormControlLabel 
                              value={item.value} 
                              control={<Radio color="primary" className="pl-0" />} 
                              label={item.label} 
                              className="m-0"
                              key={item.value}
                            />
                          ))}
                        </RadioGroup>
                      </Paper>
                    </Popper>
                  ) : null}
                </div>
              </ClickAwayListener>
            </div>
          </div>

          {(!_.isNil(selectedShipper) || invoiceDate || dueDate || disbursementDate) &&
            <div className="row">
              <div className="col-12">
                <div className="filter-container">
                  <div className="filter-list">
                    {!_.isNil(selectedShipper) &&
                      <div className="filter-item">
                        {selectedShipper.name}
                        &nbsp;&nbsp;&nbsp;
                        <Close onClick={() => selectShipper(null)} />
                      </div>
                    }
                    {invoiceDate &&
                      <div className="filter-item">
                        Invoice Disetujui: {moment(invoiceDate).format('DD MMM YYYY')}
                        &nbsp;&nbsp;&nbsp;
                        <Close onClick={() => onDateChange('invoice_approved_at', '')} />
                      </div>
                    }
                    {dueDate &&
                      <div className="filter-item">
                        Batas Pembayaran: {moment(dueDate).format('DD MMM YYYY')}
                        &nbsp;&nbsp;&nbsp;
                        <Close onClick={() => onDateChange('repayment_due_date', '')} />
                      </div>
                    }
                    {disbursementDate &&
                      <div className="filter-item">
                        Permintaan Pencairan: {moment(disbursementDate).format('DD MMM YYYY')}
                        &nbsp;&nbsp;&nbsp;
                        <Close onClick={() => onDateChange('disbursement_date', '')} />
                      </div>
                    }
                  </div>
                  <div className="text-ritase pointer" onClick={() => resetFilter()}>Clear All</div>
                </div>
              </div>
            </div>
          }
          
          <div className="row">
            <div className="col-12">
              <TableContainer className="table-card">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {isBulk && <TableCell></TableCell>}
                      <TableCell>ID Invoice</TableCell>
                      <TableCell>Shipper</TableCell>
                      <TableCell>Transporter</TableCell>
                      <TableCell>Invoice Disetujui</TableCell>
                      <TableCell>Tanggal Permintaan Pencairan</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {is_fetching ?
                      <TableRow>
                        <TableCell align="center" colSpan={7} className="border-0">
                          Loading
                        </TableCell>
                      </TableRow>
                    : list.length > 0 ?
                      list.map((item, i) => {
                        const isItemSelected = isSelected(item.id);
                        item.isCompanyOrder = _.get(item, 'invoice.target') === 'company_order';
                        return(
                          <TableRow 
                            key={i} 
                            aria-checked={isBulk && isItemSelected} 
                            selected={isBulk && isItemSelected}
                          >
                            {isBulk &&
                              <TableCell className="p-0 pt-2">
                                <Checkbox
                                  checked={isItemSelected}
                                  onClick={(event) => handleClick(event, item)}
                                  color="primary"
                                />
                              </TableCell>
                            }
                            <TableCell>
                              <div>
                                {_.get(item, 'invoice_id', false) ?
                                  tdsSingkat(item.invoice_id)
                                :
                                  '-'
                                }
                              </div>
                              <div className="mt-3">
                                {_.get(item, 'id', false) ?
                                  'SCF : ' + tdsSingkat(item.id)
                                :
                                  '-'
                                }
                              </div>
                              <Link to={{pathname: `/dashboard/${item.id}`, state: { prevPath: 'dashboard' }}} className="mt-4 text-ritase pointer block">Detail Invoice</Link>
                            </TableCell>
                            <TableCell>
                              <div className="flex align-items-top">
                                {_.get(item, 'invoice.items[0].order.company_shipper.image_url', false) ?
                                  <img src={item.invoice.items[0].order.company_shipper.image_url} alt="" className="shipper_initial" />

                                :
                                  item.isCompanyOrder ?
                                  <img src={getDefaultImage( _.get(item, 'invoice.items[0].order.transporter_shipper.name', ''))} alt="" className="shipper_initial" />
                                  :
                                  <img src={getDefaultImage( _.get(item, 'invoice.items[0].order.company_shipper.name', ''))} alt="" className="shipper_initial" />
                                  // <div className="shipper_initial">
                                  //   { _.get(item, 'invoice.items[0].order.company_shipper.name', '').substr(0,1) }
                                  // </div>
                                }
                                <div>
                                  <div className="bolder">{ item.isCompanyOrder ? _.get(item, 'invoice.items[0].order.transporter_shipper.name', '-') : _.get(item, 'invoice.items[0].order.company_shipper.name', '-') }</div>
                                  <div className="text-grey">
                                    { item.isCompanyOrder ? 'Ritase' : _.capitalize(_.get(item, 'invoice.target', '-'))}
                                  
                                    {_.get(item, 'invoice.items[0].order.company_shipper.company_type') === 'client' && 
                                      <span className="level-badge-wrapper inline">
                                        {_.get(item, 'invoice.items[0].order.company_shipper.company_rating.badge_level') ?
                                          <div>
                                            {_.map(Array.from('x'.repeat(getSublevel(_.get(item, 'invoice.items[0].order.company_shipper.company_rating.badge_level')))), (x, i) => (
                                              <span 
                                                key={i}
                                                className={`level-badge ${getLevel(_.get(item, 'invoice.items[0].order.company_shipper.company_rating.badge_level'))}`}
                                              > </span>
                                            ))}
                                          </div>
                                        :
                                          <div>
                                            <span 
                                              className="level-badge no_badge"
                                            > </span>
                                          </div>
                                        }
                                      </span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              {_.get(item, 'company_borrower_id.name', '-')}
                              {_.get(item, 'company_borrower_id.company_type') === 'transport' && 
                                <div className="level-badge-wrapper">
                                  {_.get(item, 'company_borrower_id.company_rating.badge_level') ?
                                    <div>
                                      {_.map(Array.from('x'.repeat(getSublevel(_.get(item, 'company_borrower_id.company_rating.badge_level')))), (x, i) => (
                                        <span 
                                          key={i}
                                          className={`level-badge ${getLevel(_.get(item, 'company_borrower_id.company_rating.badge_level'))}`}
                                        > </span>
                                      ))}
                                    </div>
                                  :
                                    <div>
                                      <span 
                                        className="level-badge no_badge"
                                      > </span>
                                    </div>
                                  }
                                </div>
                              }
                              <div className="mt-3 text-ritase pointer" onClick={() => showDocuments(item)}>
                                Lihat Dokumen Legal
                              </div>
                            </TableCell>
                            <TableCell>
                              {_.get(item, 'invoice.finalized_at', false) ?
                                moment(item.invoice.finalized_at).format('ddd, DD MMM YYYY')
                              :
                                '-'
                              }
                            </TableCell>
                            <TableCell>
                              {_.get(item, 'disbursement_date', false) ?
                                moment(item.disbursement_date).format('ddd, DD MMM YYYY')
                              : _.get(item, 'scf_top', false) ?
                                `H + ${item.scf_top}`
                              :
                                '-'
                              }
                            </TableCell>
                            <TableCell>
                              <div className="text-action s-18 bolder">
                                {formatRupiah(_.get(item, 'request_amount', 0))}
                              </div>
                              <div>
                                <Button 
                                  variant="contained" 
                                  color="secondary" 
                                  disableElevation
                                  className="mt-3 w-100"
                                  onClick={() => chooseInvoice(item)}
                                >Pilih</Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    :
                      <TableRow>
                        <TableCell align="center" colSpan={7} className="border-0">
                          No Data Found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <Pagination 
            count={meta.count || 0}
            limit={meta.limit || 10}
            offset={meta.offset || 0}
            onChange={(e, page) => props.getList(((page - 1) * 10), params)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let { is_fetching, list, meta } = state.dashboard;
  return {
    is_fetching, list, meta
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getList: (offset, params) => dispatch(getList(offset, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInvoice);