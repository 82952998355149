import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ReactImageGallery from 'react-image-gallery';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'none'
  },
  dialog: {
    backgroundColor: '#272e34'
  }
}));

const ImageGallery = ({ open, closeModal, images }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      classes={{
        root: classes.dialog
      }}
      PaperProps={{
        elevation: 0,
        classes: {
          root: classes.paper
        }
      }}
      scroll="body"
      fullScreen
    >
      <DialogTitle>
        <Close className="pointer pull-right" onClick={() => closeModal()} style={{color: 'white', fontSize: 30}} />
      </DialogTitle>
      <DialogContent>
        <ReactImageGallery 
          items={images} 
          showFullscreenButton={false}
          showPlayButton={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageGallery;