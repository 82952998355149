import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs, Tab } from '@material-ui/core';
import _ from 'lodash';
import Page from '../Page';
import './Dashboard.scss';
import addbulk from '../../assets/icons/addbulk.svg';
import Footer from '../../components/Footer';
import BulkActionModal from './BulkActionModal';
import Swal from 'sweetalert2';
import DocumentsModal from './DocumentsModal';
import DashboardSummary from './DashboardSummary';
import DashboardInvoice from './DashboardInvoice';
import DashboardDp from './DashboardDp';

const Dashboard = (props) => {
  const { summary, user } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const [isBulk, setIsBulk] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [transporterDocuments, setTransporterDocuments] = useState(null);

  useEffect(() => {
    if (props.location.pathname !== '/dashboard') {
      props.history.push('/dashboard');
    }

    // eslint-disable-next-line
  }, []);

  const onTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isSelected = (id) => !_.isNil(_.find(selected, s => s.id === id));

  const toggleIsBulk = value => {
    setIsBulk(value);
    setSelected([]);
  }

  const showDocuments = (item) => {
    let documents = _.get(item, 'company_borrower_id.company_registration', false);
    if (documents) setTransporterDocuments(documents);
    else {
      Swal.fire('Dokumen tidak ditemukan', '', 'error');
    }
  }

  return (
    <Page title="Dashboard">
      <DashboardSummary />

      {(user && user.is_scf_dp) &&
        <Tabs
          value={currentTab}
          onChange={onTabChange}
        >
          <Tab 
            label={
              <span>
                <span>Invoice</span>
                <span className="badge badge-pill badge-ritase">{summary.invoices}</span>
              </span>
            }
          />
          <Tab
            label={
              <span>
                <span>Down Payment</span>
                <span className="badge badge-pill badge-ritase">{summary.dp}</span>
              </span>
            }
          />
        </Tabs>
      }

      {currentTab === 0 &&
        <DashboardInvoice 
          isBulk={isBulk}
          setIsBulk={setIsBulk}
          showDocuments={showDocuments}
          openModal={openModal}
          setOpenModal={setOpenModal}
          isSelected={isSelected}
          toggleIsBulk={toggleIsBulk}
          selected={selected}
          setSelected={setSelected}
        />
      }
      
      {currentTab === 1 &&
        <DashboardDp 
          isBulk={isBulk}
          setIsBulk={setIsBulk}
          showDocuments={showDocuments}
          openModal={openModal}
          setOpenModal={setOpenModal}
          isSelected={isSelected}
          toggleIsBulk={toggleIsBulk}
          selected={selected}
          setSelected={setSelected}
        />
      }

      {isBulk &&
        <Footer>
          <div>
            <strong>{selected.length} Invoice</strong> selected
          </div>
          <div>
            <Button
              variant="outlined"
              onClick={() => toggleIsBulk(false)}
              className="mr-3"
              style={{width: '120px'}}
            >
              Batal
            </Button>
            <Button 
              variant="contained"
              color="secondary" 
              disableElevation
              startIcon={<img src={addbulk} alt="add" style={{filter: 'grayscale(1) brightness(2)'}} />}
              onClick={() => setOpenModal(true)}
              disabled={selected.length === 0}
            >
              Danai Sekaligus
            </Button>
          </div>
        </Footer>
      }

      {openModal &&
        <BulkActionModal
          open={openModal}
          data={selected}
          closeModal={() => setOpenModal(false)}
          onFinish={() => currentTab === 0 ? props.history.push('/invoice') : props.history.push('/dp')}
          currentTab={currentTab}
        />
      }
      
      {Boolean(transporterDocuments) &&
        <DocumentsModal
          open={Boolean(transporterDocuments)}
          data={transporterDocuments}
          closeModal={() => setTransporterDocuments(null)}
        />
      }
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    summary: state.dashboard.summary,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(Dashboard);