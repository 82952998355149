export const GET = 'GET_USER';
export const UPDATE = 'UPDATE_USER';

export const get = (id, params = {}) => {
  params.populate = 'company,company.bank_accounts';
  
  return {
    type: GET,
    payload: {
      request: {
        url: `/users/${id}`,
        method: 'get',
        params
      }
    }
  }
}

export const update = (id, data) => {
  return {
    type: UPDATE,
    payload: {
      request: {
        url: `/users/${id}`,
        method: 'put',
        data
      }
    }
  }
}