import { Breadcrumbs, Button, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateMe, updatePassword } from '../../actions/auth';
import { getBanks } from '../../actions/banks';
import { generatePolicy } from '../../actions/upload';
import { get, update } from '../../actions/users';
import Page from '../Page';
import _ from 'lodash';
import './Profile.scss';
import defaultImage from '../../assets/icons/profilefilled.svg';
import company from '../../assets/icons/company.svg';
import passwordIcon from '../../assets/icons/password.svg';
import edit from '../../assets/icons/edit.svg';
import close from '../../assets/icons/close.svg';
import Select from '../../components/Select';
import Swal from 'sweetalert2';
import Axios from 'axios';

const Profile = (props) => {
  const bankOptions = props.banks.map(bank => {
    return { value: bank.id, label: bank.bank_name }
  });
  const [data, setData] = useState(null);
  const [activeMenu, setActiveMenu] = useState('company');
  const [name, setName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [accountName, setAccountName] = useState('');
  const [tempImageUrl, setTempImageUrl] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const showNotification = Boolean(notification);

  useEffect(() => {
    props.getUser(props.user_id).then(result => {
      let data = _.get(result, 'payload.data', null);
      setData(data);
      setName(_.get(data, 'name', ''));
      setAccountNo(_.get(data, 'company.bank_accounts[0].account_number', ''));
      setAccountName(_.get(data, 'company.bank_accounts[0].name', ''));
      setBankBranch(_.get(data, 'company.bank_accounts[0].bank_branch', ''));
      setSelectedBank({
        value: _.get(data, 'company.bank_accounts[0].bank_id'),
        label: _.get(data, 'company.bank_accounts[0].bank_name'),
      });
    });
    props.getBanks();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    var body;
    setIsLoading(true);
    if (activeMenu === 'company') {
      if (!name) {
        setNotification({type: 'error', text: 'Mohon lengkapi semua kolom'});
        setIsLoading(false);
        return;
      }

      if (accountNo.toString().length < 8 || accountNo.toString().length > 15) {
        setNotification({type: 'error', text: 'Nomor rekening harus memiliki 8 - 15 digit'});
        setIsLoading(false);
        return;
      }

      body = {
        name,
        account_number: accountNo,
        account_name: accountName,
        bank_name: selectedBank.label,
        bank_id: selectedBank.value,
        is_bank_account: 1,
        bank_branch: bankBranch,
        // id_bank_account: _.get(data, 'company.bank_accounts[0].id')
      }

      if (tempImageUrl) body.image_url = tempImageUrl;

      props.updateUser(props.user_id, body).then(result => {
        setIsLoading(false);
        if (result.type === 'UPDATE_USER_FAIL') {
          Swal.fire('Error!', result.error.message, 'error');
        } else if (_.get(result, 'payload.data.errorMessage')) {
          Swal.fire('Error!', result.payload.data.errorMessage, 'error');
        } else {
          props.updateMe(result.payload.data)
          Swal.fire('Berhasil', `Profile berhasil diperbarui`, 'success').then(() => {
            props.history.push('/dashboard');
          });
        };
      });
    } else {
      if (!password || !newPassword || !confirmPassword) {
        setNotification({type: 'error', text: 'Mohon lengkapi semua kolom'});
        setIsLoading(false);
        return;
      }

      if (newPassword !== confirmPassword) {
        setNotification({type: 'error', text: 'Password baru dan verifikasi password harus sama'});
        setIsLoading(false);
        return;
      }

      if (newPassword.length < 8 || newPassword.length > 128) {
        setNotification({type: 'error', text: 'Password harus memiliki panjang 8 - 128 karakter'});
        setIsLoading(false);
        return;
      }
  
      if (!(/(?=.*[A-z].*)(?=.*[A-Z].*)/.test(newPassword))) {
        setNotification({type: 'error', text: 'Password harus memiliki huruf besar dan kecil'});
        setIsLoading(false);
        return;
      }
  
      if (!(/^(?=.*(\d|\W)).{5,20}$/.test(newPassword))) {
        setNotification({type: 'error', text: 'Password harus mengandung minimal 1 karakter numerical (0-9) atau 1 karakter non-alpanumeric'});
        setIsLoading(false);
        return;
      }

      body = {
        old_password: password,
        new_password: newPassword
      };

      props.updatePassword(body).then(result => {
        setIsLoading(false);
        if (result.type === 'UPDATE_PASSWORD_FAIL') {
          Swal.fire('Error!', result.error.message, 'error');
        } else if (_.get(result, 'payload.data.errorMessage')) {
          Swal.fire('Error!', result.payload.data.errorMessage, 'error');
        } else {
          Swal.fire('Berhasil', `Password berhasil diubah`, 'success').then(() => {
            props.history.push('/dashboard');
          });
        };
      });
    }
  }

  const hideNotification = (e, reason) => {
    if (reason === 'clickaway')  return;

    setNotification(null);
  }

  const ISODateString = (d) => {
    function pad(n) {return n<10 ? '0'+n : n}
    return '' + d.getUTCFullYear()
      + pad(d.getUTCMonth()+1)
      + pad(d.getUTCDate())+'T'
      + '000000Z'
  }

  const handleFileChange = (file) => {
    if (file) {
      var extension = file.name.substr(file.name.lastIndexOf('.')+1);
      if (!['png', 'jpg', 'jpeg'].includes(extension.toLowerCase())) {
        setNotification({type: 'error', text: 'Allowed files : PNG, JPG, JPEG'});
        return;
      }

      var date = new Date();
      var body = {
        currentDate: date.toISOString(),
        folderName: "users/",
        redirectURL: "",
        app: "ritase"
      }

      props.generatePolicy(body).then(result => {
        if (result.type === 'GENERATE_POLICY_FAIL') {
          Swal.fire('Error!', result.error.message, 'error');
        } else if (_.get(result, 'payload.data.errorMessage')) {
          Swal.fire('Error!', result.payload.data.errorMessage, 'error');
        } else {
          var isoDate = ISODateString(date);
          let res = result.payload.data;

          var bodyFormData = new FormData();
          bodyFormData.append('key', "users/" + res.imageName + "." + extension);
          bodyFormData.append('acl', "public-read");
          bodyFormData.append('Content-Type', "image/");
          bodyFormData.append('x-amz-meta-uuid', "14365123651274");
          bodyFormData.append('X-Amz-Credential', process.env.REACT_APP_S3_ACCESSKEY + '/' + isoDate.split('T')[0] + '/ap-southeast-1/s3/aws4_request')
          bodyFormData.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256')
          bodyFormData.append('X-Amz-Date', isoDate)
          bodyFormData.append('x-amz-meta-tag', '')
          bodyFormData.append('success_action_status', '200')
          bodyFormData.append('Policy', res.data.s3Policy)
          bodyFormData.append('X-Amz-Signature', res.data.s3Signature)
          bodyFormData.append('file', file)
          
          Axios({
            method: 'post',
            url: process.env.REACT_APP_S3_PATH,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(response => {
              //handle success
              setTempImageUrl(`${process.env.REACT_APP_S3_PATH}users/${res.imageName}.${extension}`);
          }).catch(response => {
              //handle error
              Swal.fire('Error!', 'Upload foto gagal', 'error');
          });
        };
      })
    }
  }

  return (
    <Page title="My Profile">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to='/dashboard'>Dashboard</Link>
        <Typography color="textPrimary">My Profile</Typography>
      </Breadcrumbs>
      <div className="row profile">
        <div className="col-4">
          <div className="panel">
            <div className="panel-body">
              <div className="flex align-items-center mb-4">
                {_.get(data, 'image_url') ?
                  <img src={data.image_url} className="user-image" alt="user" />
                :
                  <div className="user-default">
                    <img src={defaultImage} alt="user" />
                  </div>
                }
                <div className="s-18 bolder mb-2">{_.get(data, 'name', '-')}</div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  Email
                </div>
                <div className="col-8 text-right text-grey">
                  {_.get(data, 'email', '-')}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  Bank Account
                </div>
                <div className="col-8 text-right text-grey">
                  {_.get(data, 'company.bank_accounts[0].account_number', '-')}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  Bank Name
                </div>
                <div className="col-8 text-right text-grey">
                  {_.get(data, 'company.bank_accounts[0].bank_name', '-')}
                </div>
              </div>
              <List className="mb-5">
                <ListItem 
                  button 
                  aria-selected={activeMenu === 'company'} 
                  onClick={() => setActiveMenu('company')}
                >
                  <ListItemIcon>
                    <img src={company} alt="company" />
                  </ListItemIcon>
                  <ListItemText primary="Informasi Perusahaan" />
                </ListItem>
                <ListItem 
                  button 
                  aria-selected={activeMenu === 'password'} 
                  onClick={() => setActiveMenu('password')}
                >
                  <ListItemIcon>
                    <img src={passwordIcon} alt="password" />
                  </ListItemIcon>
                  <ListItemText primary="Ubah Password" />
                </ListItem>
              </List>
            </div>
          </div>
        </div>
        
        <div className="col-8">
          <div className="panel">
            <div className="panel-header">
              {activeMenu === 'company' ? 'Informasi perusahaan' : 'Ubah password'}
            </div>

            <div className="panel-body">
              {activeMenu === 'company' ?
                <div>
                  <div className="row mb-4">
                    <div className="col-3 text-right">Foto</div>
                    <div className="col-9 change-photo">
                      {tempImageUrl ?
                        <img 
                          className="user-image" 
                          src={tempImageUrl} 
                          alt="profile" 
                        />
                      : _.get(data, 'image_url') ?
                        <img 
                          className="user-image" 
                          src={data.image_url} 
                          alt="profile"  
                        />
                      :
                        <div className="user-default">
                          <img src={defaultImage} alt="profile" />
                        </div>
                      }
                      <input 
                        type="file" 
                        onChange={(e) => handleFileChange(_.get(e, 'target.files[0]', null))} 
                        accept="image/*" 
                        id="profile-picture"
                        style={{display: 'none'}}
                      />
                      <label htmlFor="profile-picture" className="btn-photo-edit">
                        <img src={edit} alt="edit" />
                      </label>
                      <div className="btn-photo-delete" onClick={() => setTempImageUrl(null)}>
                        <img src={close} alt="close" />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-3"></div>
                    <div className="col-9 s-12 text-grey">Allowed files : PNG, JPG, JPEG</div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-3 text-right">Nama</div>
                    <div className="col-9">
                      <input 
                        placeholder="Nama"
                        className="form-control"
                        value={name} 
                        onChange={(e) => setName(e.target.value)}   
                      />
                    </div>
                  </div>
                  
                  <div className="row align-items-center mt-4 mb-3">
                    <div className="col-3 text-right"></div>
                    <div className="col-9 s-16 bolder">Informasi akun bank</div>
                  </div>
                  
                  <div className="row align-items-center">
                    <div className="col-3 text-right">No rekening</div>
                    <div className="col-9">
                      <input 
                        type="number"
                        placeholder="No rekening"
                        className="form-control"
                        value={accountNo} 
                        onChange={(e) => setAccountNo(e.target.value)}   
                      />
                    </div>
                  </div>
                  
                  <div className="row align-items-center mt-4">
                    <div className="col-3 text-right">Nama rekening</div>
                    <div className="col-9">
                      <input 
                        placeholder="Nama pemilik rekening"
                        className="form-control"
                        value={accountName} 
                        onChange={(e) => setAccountName(e.target.value)}   
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mt-4">
                    <div className="col-3 text-right">Nama bank</div>
                    <div className="col-9">
                      <Select 
                        placeholder="Pilih bank"
                        options={bankOptions}
                        value={selectedBank}
                        onChange={(value) => setSelectedBank(value)}
                      />
                    </div>
                  </div>
                  
                  <div className="row align-items-center mt-4">
                    <div className="col-3 text-right">Cabang</div>
                    <div className="col-9">
                      <input 
                        placeholder="Cabang bank"
                        className="form-control"
                        value={bankBranch} 
                        onChange={(e) => setBankBranch(e.target.value)}   
                      />
                    </div>
                  </div>
                </div>
              :
                <div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Password saat ini</div>
                    <div className="col-9">
                      <input 
                        type="password"
                        placeholder="Masukkan password saat ini"
                        className="form-control"
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)}   
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Password baru</div>
                    <div className="col-9">
                      <input 
                        type="password"
                        placeholder="Masukkan password baru"
                        className="form-control"
                        value={newPassword} 
                        onChange={(e) => setNewPassword(e.target.value)}   
                      />
                    </div>
                  </div>
                  
                  <div className="row align-items-center">
                    <div className="col-3">Verifikasi password</div>
                    <div className="col-9">
                      <input 
                        type="password"
                        placeholder="Ketik ulang password baru"
                        className="form-control"
                        value={confirmPassword} 
                        onChange={(e) => setConfirmPassword(e.target.value)}   
                      />
                    </div>
                  </div>
                </div>
              }
              
            </div>
            
            <div className="panel-footer">
              <Button variant="outlined" className="mr-3" onClick={() => props.history.push('/')}>Batal</Button>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : 'Simpan'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    
      <Snackbar 
        open={showNotification} 
        autoHideDuration={3000} 
        onClose={hideNotification}
        anchorOrigin={{ 
          vertical: 'top', 
          horizontal: 'center'
        }}
      >
        <Alert 
          onClose={hideNotification} 
          severity={_.get(notification, 'type', 'error')} 
          elevation={6} 
          variant="filled"
          className="s-14"
        >
          {_.get(notification, 'text', '')}
        </Alert>
      </Snackbar>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    banks: state.banks,
    user_id: _.get(state, 'auth.user.id', '')
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: (id) => dispatch(get(id)),
    getBanks: () => dispatch(getBanks()),
    updatePassword: (data) => dispatch(updatePassword(data)),
    updateUser: (id, data) => dispatch(update(id, data)),
    generatePolicy: (data) => dispatch(generatePolicy(data)),
    updateMe: (data) => dispatch(updateMe(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);