import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { resetPassword } from '../../actions/auth';

const ResetPassword = (props) => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = new URL(document.location.href);
    const searchParams = new URLSearchParams(url.search);
    setToken(searchParams.get('token'));
    setEmail(searchParams.get('email'));
  }, [])

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (password !== confirmPassword) {
      Swal.fire('Error', 'Password dan konfirmasi password tidak sama', 'error');
      setIsLoading(false);
      return;
    };

    if (!token || !email) {
      Swal.fire('Error', 'Link tidak valid. Silakan periksa kembali link yang dikirim ke email anda.', 'error');
      setIsLoading(false);
      return;
    }

    if (password.length < 8 || password.length > 128) {
      Swal.fire('Error', 'Password harus memiliki panjang 8 - 128 karakter', 'error');
      setIsLoading(false);
      return;
    }

    if (!(/(?=.*[A-z].*)(?=.*[A-Z].*)/.test(password))) {
      Swal.fire('Error', 'Password harus memiliki huruf besar dan kecil', 'error');
      setIsLoading(false);
      return;
    }

    if (!(/^(?=.*(\d|\W)).{5,20}$/.test(password))) {
      Swal.fire('Error', 'Password harus mengandung minimal 1 karakter numerical (0-9) atau 1 karakter non-alpanumeric', 'error');
      setIsLoading(false);
      return;
    }

    let body = {
      password,
      token,
      email
    }

    props.resetPassword(body).then(result => {
      setIsLoading(false);
      if (result.type === 'RESET_PASSWORD_FAIL') {
        Swal.fire('Error!', result.error.message, 'error');
      } else if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        Swal.fire({
          title: 'Berhasil',
          text: 'Password Anda telah diganti.',
          icon: 'success',
          confirmButtonColor: '#27a397'
        }).then(() => {
          props.history.push('/login');
        });
      }
    });
  }

  return (
    <div className="mt-4 mb-4">
      <div className="s-18 bolder">RESET PASSWORD</div>
      <div className="text-grey mt-1">Silahkan masukkan password baru Anda</div>
      <form onSubmit={submitForm}>
        <div className="mt-3 form-group">
          <label htmlFor="password">Password</label>
          <input 
            type="password"
            placeholder="Password baru" 
            className="form-control"
            onChange={(e) => setPassword(e.target.value)} 
          />
        </div>
        
        <div className="mt-3 form-group">
          <label htmlFor="password">Konfirmasi Password</label>
          <input 
            type="password"
            placeholder="Konfirmasi password baru" 
            className="form-control"
            onChange={(e) => setConfirmPassword(e.target.value)} 
          />
        </div>

        <div className="text-right">
          <Button 
            type="submit" 
            color="primary" 
            disabled={!password || !confirmPassword || isLoading}
            variant="contained"
            className="mt-2"
            disableElevation
            style={{ width: '92px' }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'KIRIM'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
	return {
    resetPassword: (data) => dispatch(resetPassword(data)),
  }
}

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword));