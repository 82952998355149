import { combineReducers } from "redux";
import { TOGGLE } from "../actions/sidebar";

const show = (state = true, action) => {
  switch (action.type) {
    case TOGGLE:
      return !state;
    default:
      return state;
  }
}

export default combineReducers({ show });