import { combineReducers } from 'redux'
import auth from './auth'
import banks from './banks'
import dashboard from './dashboard'
import dp from './dp'
import invoice from './invoice'
import sidebar from './sidebar'
import users from './users'
import virtualaccount from './virtualaccount'
import config from './config'
import topup_plan from './topup_plan'

export default combineReducers({ 
  auth,
  banks,
  dashboard,
  dp,
  invoice,
  sidebar,
  users,
  virtualaccount,
  config,
  topup_plan
});