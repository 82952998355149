import { GET } from "../actions/users";
import _ from 'lodash';
import { combineReducers } from 'redux';

const detail = (state = {}, action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      return _.get(action, 'payload.data', null);
    case GET + '_FAIL':
      return {};
    default:
      return state;
  }
}

export default combineReducers({ detail });