import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { formatRupiah, getLevel, getSublevel, uploader } from '../../lib/utils';
import _ from 'lodash';
import {useDropzone} from 'react-dropzone';
import { paymentProofTopupPlan } from '../../actions/scf';
import Swal from 'sweetalert2';

const TransferProofModal = (props) => {
  const { open, closeModal, data, totalTransaction, onFinish } = props;
  const [files, setFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    getFilesFromEvent: (event) => {
      const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      const filteredFile = _.filter(fileList, file => _.includes(file.type, 'image'));
      const temp = [...files, ...filteredFile]
      setFiles(temp)
      return temp
    },
  });

  const handleSubmit = () => {
    setIsLoading(true);
    const image_urls = [];
    
    _.each(files, (file, index) => {
      uploader(file, (response, file_url) => {
        image_urls.push(file_url)
        
        if (index === files.length - 1) {
          uploadPaymentProof(image_urls)
        }
      })
    })
  }

  const uploadPaymentProof = image_urls => {
    setIsLoading(true);

    let id = _.get(data, '[0].topup_plan[0].id');
    let body = {
      image_urls
    }
    
    props.paymentProofTopupPlan(id, body).then(result => {
      setIsLoading(false);
      if (result.type === 'PAYMENT_PROOF_TOPUP_PLAN_FAIL') {
        Swal.fire('Error!', result.error.message, 'error');
      } else if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        closeModal();
        Swal.fire('Berhasil', `Bukti pembayaran berhasil disimpan`, 'success').then(() => {
          onFinish();
        });
      };
    })
  }

  const transporter = _.get(data, '[0].borrower_request.company_borrower_id', {});
  
  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle onClose={() => closeModal()} className="border-bottom">
        Bukti Pembayaran
        <Close className="pointer pull-right" onClick={() => closeModal()} />
      </DialogTitle>
      <DialogContent>
        <div className="py-3">
          <div className="row mb-3">
            <div className="col-6">
              Transporter
            </div>
            <div className="col-6 text-right bolder">
              <div className="flex align-items-center justify-content-end">
                <div>
                  { _.get(transporter, 'name') || '-' }
                </div>

                {_.get(transporter, 'company_type') === 'transport' && 
                  <div className="level-badge-wrapper m-0 ml-2">
                    {_.get(transporter, 'company_rating.badge_level') ?
                      <div>
                        {_.map(Array.from('x'.repeat(getSublevel(_.get(transporter, 'company_rating.badge_level')))), (x, i) => (
                          <span 
                            key={i}
                            className={`level-badge ${getLevel(_.get(transporter, 'company_rating.badge_level'))}`}
                          > </span>
                        ))}
                      </div>
                    :
                      <div>
                        <span 
                          className="level-badge no_badge"
                        > </span>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              Jumlah Transaksi
            </div>
            <div className="col-6 text-right bolder">
            { _.get(data, 'length', 0) || 0 }
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              Total Nilai Pembayaran
            </div>
            <div className="col-6 text-right bolder">
              {formatRupiah(totalTransaction)}
            </div>
          </div>

          <hr className="my-4" />

          <div className="bolder">
            Upload Bukti Transfer
          </div>

          <section className="dropzone-container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <div>Drop files here or click to upload</div>
            </div>
            {files.length > 0 &&
              <div className="mt-2">
                <div>File :</div>
                {files.map((file, i) => (
                  <div 
                    key={i}
                    className="text-ritase pointer mb-1"
                    onClick={() => setImageUrl(URL.createObjectURL(file))}
                  >
                    {file.name}
                  </div>
                ))}
              </div>
            }
          </section>
        </div>
      </DialogContent>
      <DialogActions className="border-top p-3">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>

      <Dialog
        open={Boolean(imageUrl)}
        onClose={() => setImageUrl(null)}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle onClose={() => setImageUrl(null)} className="border-bottom">
          Bukti Transfer
          <Close className="pointer pull-right" onClick={() => setImageUrl(null)} />
        </DialogTitle>
        <DialogContent>
          <img className="w-100" src={imageUrl} alt={imageUrl} />
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    paymentProofTopupPlan: (id, data) => dispatch(paymentProofTopupPlan(id, data)),
  }
}

export default connect(null, mapDispatchToProps)(TransferProofModal);