import React from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { formatRupiah } from '../../lib/utils';
import _ from 'lodash';
import { bookInvoice } from '../../actions/scf'
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const BulkActionModal = (props) => {
  const { open, closeModal, data, onFinish, currentTab } = props;
  const handleSubmit = () => {
    let ids = data.map(item => {
      return {
        id: item.id
      }
    });
    let body = {};
    if (currentTab === 0) {
      body.invoices = ids;
    } else {
      body.scf_dp = ids;
    }

    props.bookInvoice(body).then(result => {
      closeModal();
      if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage, 'error');
      } else {
        Swal.fire('Berhasil', `${data.length} ${ currentTab === 0 ? 'invoice' : 'DP' } berhasil diajukan`, 'success').then(() => {
          onFinish();
        });
      };
    });
  }

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle onClose={() => closeModal()} className="border-bottom">
        Konfirmasi Pengajuan
        <Close className="pointer pull-right" onClick={() => closeModal()} />
      </DialogTitle>
      <DialogContent>
        <div className="row mt-3">
          <div className="col-6">List { currentTab === 0 ? 'invoice' : 'DP' } yang diajukan</div>
          <div className="col-6 text-right bolder">Total : {data.length}</div>
        </div>
        <div className="mt-3 mb-4">
          {data.map(item => (
            <div className="row border-bottom pt-2 pb-2 align-items-center" key={item.id}>
              <div className="col-6 text-ritase"><Link to={{pathname: `/dashboard/${item.id}`, state: { prevPath: 'dashboard' }}} target="blank">{item.id || '-'}</Link></div>
              <div className="col-6 text-right text-action s-18 bolder">
                {formatRupiah(_.get(item, 'request_amount', 0))}
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions className="border-top p-3">
        <Button variant="outlined" onClick={() => closeModal()}>Batal</Button>
        <Button variant="contained" color="secondary" disableElevation onClick={handleSubmit}>Ajukan</Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    bookInvoice: (data) => dispatch(bookInvoice(data))
  }
}

export default connect(null, mapDispatchToProps)(BulkActionModal);