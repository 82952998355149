export const GET_BANKS = 'GET_BANKS';
export const GET_VA_NUMBER = 'GET_VA_NUMBER';
export const RESET_VA_NUMBER = 'RESET_VA_NUMBER';

export const getBanks = (type) => {
  return {
    type: GET_BANKS,
    payload: {
      request: {
        url: `/virtual_accounts/${type}/banks`,
        method: 'get'
      }
    }
  }
}

export const getVaNumber = (type, params = {}) => {
    return {
        type: GET_VA_NUMBER,
        payload: {
          request: {
            url: `/virtual_accounts/${type}/virtual_accounts`,
            method: 'get',
            params
          }
        }
      }
}

export const resetVaNumber = () => ({
  type: RESET_VA_NUMBER,
});