import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { AppBar, ClickAwayListener, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Popper, Toolbar, Typography } from '@material-ui/core';
import _ from 'lodash';
import { logout } from '../../actions/auth';
import profile from '../../assets/icons/profile.svg';
import logoutIcon from '../../assets/icons/logout.svg';

const Header = (props) => {
  const { title, user, classes, showSidebar } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const idEl = open ? 'simple-popper' : undefined;
  const name = _.capitalize(_.get(user, 'name', ''));
  
  const toggleMenu = (value) => {
    setAnchorEl(value);
  };

  const handleLogout = () => {
    toggleMenu(null);
    props.logout().then(result => {
      props.history.push('/login');
    });
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: showSidebar,
      })}
    >
      <Toolbar style={{justifyContent: 'space-between'}}>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        <div style={{fontSize: '12px'}}>
          <span style={{color: '#9e9e9e'}}>Hi,</span>
          <span style={{fontWeight: '700'}}> {name.split(' ')[0]}</span>
          
          <ClickAwayListener onClickAway={() => toggleMenu(null)}>
            <div className="inline-block">
              {_.get(user, 'image_url', false) ? 
                <img 
                  src={user.image_url} 
                  alt="user" 
                  style={{width: '32px', height: '32px', borderRadius: '5px'}} 
                  onClick={(e) => toggleMenu(anchorEl ? null : e.currentTarget)} 
                  aria-describedby={idEl}
                  className="border pointer ml-3"
                />
              :
                <div
                  onClick={(e) => toggleMenu(anchorEl ? null : e.currentTarget)} 
                  aria-describedby={idEl}
                  className="header-initial"
                >
                  {name.substring(0,1)}
                </div>
              }
              {open ? (
                <Popper 
                  id={idEl}
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                  className="header-dropdown"
                >
                  <Paper>
                    <List
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader" className="border-bottom p-3 s-14" disableSticky>
                          <div className="text-black flex align-items-center">
                            {_.get(user, 'image_url', false) ? 
                              <img 
                                src={user.image_url} 
                                alt="user" 
                                className="border mr-3" 
                                style={{width: '45px', height: '45px', borderRadius: '5px'}} 
                              />
                            :
                              <div 
                                className="header-initial m-0 mr-3 s-24" 
                                style={{width: '45px', height: '45px', lineHeight: '2'}}
                              >
                                {name.substring(0,1)}
                              </div>
                            }
                            {name}
                          </div>
                        </ListSubheader>
                      }
                    >
                      <Link to='/profile'>
                        <ListItem button>
                          <ListItemIcon>
                            <img src={profile} alt="profile" />
                          </ListItemIcon>
                          <ListItemText primary="My Profile" />
                        </ListItem>
                      </Link>
                      <ListItem button onClick={() => handleLogout()}>
                        <ListItemIcon>
                          <img src={logoutIcon} alt="logout" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>
                    </List>
                  </Paper>
                </Popper>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    showSidebar: state.sidebar.show
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));