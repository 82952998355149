import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { getMe } from './actions/auth';
import Login from './view/Login';
import Dashboard from './view/Dashboard';
import InvoiceDetail from './view/InvoiceDetail';
import InvoiceList from './view/InvoiceList';
import Profile from './view/Profile';
import DpDetail from './view/DpDetail';
import DpList from './view/DpList';
import TopupPlan from './view/TopupPlan';

class routesComponent extends Component {
  componentDidMount() {
    if (this.props.token) {
      this.props.getMe();
    }
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/forgot' component={Login} />
            <Route path='/reset' component={Login} />
            <Route path='/dashboard/:id' component={InvoiceDetail} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/invoice/:id' component={InvoiceDetail} />
            <Route path='/invoice' component={InvoiceList} />
            <Route path='/dp/:id' component={DpDetail} />
            <Route path='/dp' component={DpList} />
            <Route path='/topup-plan' component={TopupPlan} />
            <Route path='/profile' component={Profile} />
            <Route component={Dashboard} />
          </Switch>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  return {
      token: state.auth.token
  }
}

const mapDispatchToProps = dispatch => ({
  getMe: () => dispatch(getMe())
})

export default connect(mapStateToProps, mapDispatchToProps)(routesComponent)
