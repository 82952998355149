import { GET_DETAIL_LEND, GET_LEND } from "../actions/scf";
import _ from 'lodash';
import { combineReducers } from "redux";
import { defaultMeta } from "../lib/utils";

const is_fetching = (state = false, action) => {
  switch (action.type) {
    case GET_LEND:
      return true;
    default:
      return false;
  }
};

const list = (state = [], action) => {
  switch (action.type) {
    case GET_LEND + '_SUCCESS':
      return _.get(action, 'payload.data.rows', []);
    case GET_LEND + '_FAIL':
      return [];
    default:
      return state;
  }
}

const meta = (state = defaultMeta, action) => {
  switch (action.type) {
    case GET_LEND + '_SUCCESS':
      if (action.payload.data && !action.payload.data.errorMessage) {
        let { count, limit, offset } = action.payload.data
        return {
          count, limit, offset
        };
      }

      return defaultMeta;
    case GET_LEND + '_FAIL':
      return defaultMeta;
    default:
      return state;
  }
}

const detail = (state = {}, action) => {
  switch (action.type) {
    case GET_DETAIL_LEND + '_SUCCESS':
      return _.get(action, 'payload.data', {});
    case GET_DETAIL_LEND + '_FAIL':
      return {}
    default:
      return state;
  }
}

export default combineReducers({ is_fetching, list, meta, detail });