import React from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';

const Pagination = (props) => {
  const { count, limit, offset } = props;
  const currentPage = Math.ceil(offset / limit) + 1;
  const totalPage = Math.ceil(count / limit);

  return (
    count > 0 ?
      <div className="row mt-3">
        <div className="col-4">
          Displaying {totalPage > currentPage ? limit : (count % limit) > 0 ? (count % limit) : limit} of {count} data
        </div>
        <div className="col-8 text-right">
          <MaterialPagination 
            {...props}
            defaultPage={1}
            page={currentPage}
            count={totalPage} 
            shape="rounded" 
            siblingCount={2}
            color="primary"
          />
        </div>
      </div>
    : null
  );
};

export default Pagination;