import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs, Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Page from '../Page';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import { formatRupiah, getFileName } from '../../lib/utils';
import { getDetailBorrow, getDetailLend, bookInvoice } from '../../actions/scf';
import Swal from 'sweetalert2';
import DocumentsModal from '../Dashboard/DocumentsModal';
import { Close } from '@material-ui/icons';

const DpDetail = (props) => {
  const id = props.match.params.id;
  const currentPage = id.includes('TDS-BRQ') ? 'borrow' : 'lend';
  const prevPath = currentPage === 'borrow' ? 'dashboard' : 'dp';
  const data = currentPage === 'borrow' ? props.dataBorrow : props.dataLend;
  const order = currentPage === 'borrow' ? _.get(data, 'order', {}) : _.get(data, 'borrower_request.order', {});
  const company_borrower = currentPage === 'borrow' ? _.get(data, 'company_borrower_id', {}) : _.get(data, 'borrower_request.company_borrower_id', {});
  const borrow_request = currentPage === 'borrow' ? data : _.get(data, 'borrower_request', {});
  const loan_dp_items = JSON.parse(_.get(borrow_request, 'loan_dp_items', '{}'));
  const [transporterDocuments, setTransporterDocuments] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const total_dp = _.reduce(loan_dp_items, (result, value) => {
    return result + value;
  });
  
  const loan_dp_array = [];
  _.each(loan_dp_items, (value, key) => {
    loan_dp_array.push({
      value, key
    })
  });

  useEffect(() => {
    let params;
    if (currentPage === 'borrow') {
      params = {
        populate: 'company_borrower_id,order,company_borrower_id.company_rating.badge_level,order.company_shipper.company_rating.badge_level,order.origin,order.destination,company_borrower_id.company_registration'
      }
      props.getDetailBorrow(id, params);
    } else {
      params = {
        populate: 'borrower_request,borrower_request.company_borrower_id,borrower_request.order,borrower_request.company_borrower_id.company_rating.badge_level,borrower_request.order.company_shipper.company_rating.badge_level,borrower_request.company_borrower_id.company_registration,company_lender_id.config,verify_paid_date,borrower_request.proof,repayment_date'
      }
      props.getDetailLend(id, params);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    let body = {
      scf_dp: [{id}]
    };
    
    props.bookInvoice(body).then(result => {
      if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage.split('\n')[0], 'error');
      } else {
        Swal.fire('Berhasil', `Invoice berhasil diajukan`, 'success').then(() => {
          props.history.push('/dashboard');
        });
      };
    });
  }

  const showDocuments = () => {
    let documents = _.get(company_borrower, 'company_registration', false);
    if (documents) setTransporterDocuments(documents);
    else {
      Swal.fire('Dokumen tidak ditemukan', '', 'error');
    }
  }
  
  return (
    <Page title="Detail Down Payment">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={`/${prevPath}`}>
          {prevPath === 'dashboard' ? _.capitalize(prevPath) : 'Down Payment'}
        </Link>
        <Typography color="textPrimary">Detail Down Payment</Typography>
      </Breadcrumbs>
      
      <div className="panel mt-4" style={{marginBottom: currentPage === 'borrow' ? '100px' : '20px'}}>
        <div className="panel-header">
          Informasi Down Payment
        </div>
        <div className="panel-body">
          <div className="pb-4 border-dashed-bottom">
            <div className="row mb-4">
              <div className="col-4">
                <div className="text-grey">Shipper</div>
                <div className="mt-2">
                  {_.get(order, 'company_shipper.name', '-')}
                </div>
              </div>
              <div className="col-4">
                <div className="text-grey">Transporter</div>
                <div className="mt-2">{_.get(company_borrower, 'name', '-')}</div>
              </div>
              <div className="col-4">
                <div className="text-grey">Status</div>
                <div className="mt-2">
                  {_.capitalize(_.get(data, 'status', '-'))}
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-4">
              <div className="text-grey">ID Order</div>
                {_.get(order, 'id', '-')}
              </div>
              <div className="col-4">
              <div className="text-grey">DP Disetujui</div>
                <div className="mt-2">
                  {_.get(borrow_request, 'verified_dp_at', false) ?
                    moment(borrow_request.verified_dp_at).format('dddd, DD MMM YYYY')
                  :
                    '-'
                  }
                </div>
              </div>
              <div className="col-4">
                <div className="text-grey">Rencana Pengembalian Dana</div>
                <div className="mt-2">
                  {_.get(data, 'repayment_due_date', false) ?
                    moment(data.repayment_due_date).format('dddd, DD MMM YYYY')
                  :
                    '-'
                  }
                </div>
              </div>
            </div>

            {['paid', 'repaid'].includes(_.get(data, 'status', '-')) &&
              <div className="row mb-4">
                <div className="col-4">
                  <div className="text-grey">Tanggal Pembayaran</div>
                  <div className="mt-2">
                    {_.get(data, 'verify_paid_date.created_at', false) ?
                      <div>{moment(data.verify_paid_date.created_at).format('dddd, DD MMM YYYY')}</div>
                    :
                      '-'
                    }
                  </div>
                </div>
                {_.get(data, 'status') === 'repaid' &&
                  <div className="col-4">
                    <div className="text-grey">Tanggal Pengembalian</div>
                    <div className="mt-2">
                      {_.get(data, 'repayment_date.created_at', false) ?
                        moment(data.repayment_date.created_at).format('dddd, DD MMM YYYY')
                      :
                        '-'
                      }
                    </div>
                  </div>
                }
                {_.get(data, 'status') === 'repaid' &&
                  <div className="col-4">
                    <div className="text-grey">Bukti Pengembalian</div>
                    <div className="mt-2">
                      {_.get(data, 'borrower_request.proof.length', 0) > 0 ?
                        data.borrower_request.proof.map(proof => (
                          <div 
                            className="pointer text-ritase" 
                            onClick={() => setImageUrl(proof.image_url)}
                          >
                            {getFileName(proof.image_url)}
                          </div>
                        ))
                      :
                        '-'
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>

          <div className="pb-4 mt-4 border-dashed-bottom">
            <ul className="list-group location">
              <li className="list-group-item">
                <label>Origin</label>
                <strong>
                  {_.get(order, 'origin_contact_name')} -{" "}
                  {_.get(order, 'origin_contact_number')}
                </strong>
                <label>{_.get(order, 'origin.address')}</label>
              </li>
              <li className="list-group-item">
                <label>Destination</label>
                <strong>
                  {_.get(order, 'destination_contact_name')} -{" "}
                  {_.get(order, 'destination_contact_number')}
                </strong>
                <label>{_.get(order, 'destination.address')}</label>
              </li>
            </ul>
          </div>

          <div className="pb-4 mt-4 border-dashed-bottom">
            <div className="s-18 mb-2">Dokumen</div>
            <div className="row align-items-center mt-2">
              <div className="col-6 bolder">Dokumen Legal</div>
              <div className="col-6 text-right">
                <Button 
                  color="primary" 
                  variant="contained" 
                  disableElevation
                  onClick={() => showDocuments()}
                >
                  Lihat Dokumen
                </Button>
              </div>
            </div>
          </div>
          
          <div className="pb-4 mt-4">
            <div className="s-18 mb-2">Rincian Biaya</div>
            <div className="row mb-2">
              <div className="col-6 text-muted">
                Keterangan
              </div>
              <div className="col-6 text-muted text-right">
                Nilai
              </div>
            </div>
            {_.map(loan_dp_array, (item, i) => (
              <div className="row mb-1" key={i}>
                <div className="col-6">
                  { item.key }
                </div>
                <div className="col-6 text-right">
                  { formatRupiah(item.value) }
                </div>
              </div>
            ))}

            <hr />

            <div className="row mb-1">
              <div className="col-6">
                Total Pengajuan DP
              </div>
              <div className="col-6 text-right">
                { formatRupiah(total_dp) }
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-6">
                PPN ({_.get(borrow_request, 'ppn_percentage', 0) || 0}%)
              </div>
              <div className="col-6 text-right">
                { formatRupiah(_.get(borrow_request, 'ppn', 0)) }
              </div>
            </div>

            {_.get(borrow_request, 'pph') &&
              <div className="row mb-1">
                <div className="col-6">
                  PPh {_.get(borrow_request, 'pph_type', '') || ''} ({_.get(borrow_request, 'pph_percentage', 0) || 0}%)
                </div>
                <div className="col-6 text-right">
                  { formatRupiah(_.get(borrow_request, 'pph', 0)) }
                </div>
              </div>
            }

            <div className="row mb-1">
              <div className="col-6">
                Biaya SCF DP
              </div>
              <div className="col-6 text-right">
                { formatRupiah(_.get(borrow_request, 'scf_fee', 0)) }
              </div>
            </div>

            <hr />

            <div className="row mb-1">
              <div className="col-6">
                Dana yang diterima transporter
              </div>
              <div className="col-6 text-right">
                { formatRupiah(_.get(borrow_request, 'request_amount', 0)) }
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {currentPage === 'borrow' &&
        <Footer>
          <Button variant="outlined" onClick={() => window.history.back()}>Kembali</Button>
          <div>
            <div className="flex align-items-center">
              <div>
                <div className="mb-1">Nilai Down Payment</div>
                <div className="text-action s-18 bolder">{formatRupiah(_.get(data, 'request_amount', 0))}</div>
              </div>
              <div>
                <Button 
                  variant="contained" 
                  color="secondary"
                  disableElevation
                  className="ml-4"
                  onClick={handleSubmit}
                >Ajukan Pendanaan</Button>
              </div>
            </div>
          </div>
        </Footer>
      }

      {Boolean(transporterDocuments) &&
        <DocumentsModal
          open={Boolean(transporterDocuments)}
          data={transporterDocuments}
          closeModal={() => setTransporterDocuments(null)}
        />
      }

      {Boolean(imageUrl) &&
        <Dialog
          open={Boolean(imageUrl)}
          onClose={() => setImageUrl(null)}
          maxWidth="lg"
          fullWidth
          scroll="paper"
        >
          <DialogTitle onClose={() => setImageUrl(null)} className="border-bottom">
            Bukti Pengembalian
            <Close className="pointer pull-right" onClick={() => setImageUrl(null)} />
          </DialogTitle>
          <DialogContent>
            <img className="w-100" src={imageUrl} alt={imageUrl} />
          </DialogContent>
        </Dialog>
      }
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    dataBorrow: state.dashboard.detail,
    dataLend: state.invoice.detail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDetailBorrow: (id, params) => dispatch(getDetailBorrow(id, params)),
    getDetailLend: (id, params) => dispatch(getDetailLend(id, params)),
    bookInvoice: (data) => dispatch(bookInvoice(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DpDetail);