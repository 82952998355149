import React from 'react';
import ReactSelect from 'react-select';

const Select = (props) => {
  const { noBorder } = props;
  const customStyles = {
    control: styles => ({
      ...styles,
      border: noBorder ? 'none' : '1px solid #ccc',
      boxShadow: 'none'
    }),
    placeholder: styles => ({
      ...styles,
      color: noBorder ? '#404040' : '#ccc',
      whiteSpace: 'nowrap'
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: noBorder ? '#404040' : '#ccc'
    }),
    valueContainer: styles => ({
      ...styles,
      padding: noBorder ? '0' : '2px 8px'
    })
  }

  return (
    <ReactSelect 
      {...props}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null
      }}
    />
  );
};

export default Select;