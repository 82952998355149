import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import Page from '../Page';
import { formatRupiah, getCountdown, getLevel, getSublevel, tdsSingkat } from '../../lib/utils';
import { Link } from 'react-router-dom';
import PaymentMethodModal from '../InvoiceList/PaymentMethodModal';
import { createTopupPlan, getTopupPlan, getBatchTopupPlan, cancelTopupPlan } from '../../actions/scf';
import { getCompanies } from '../../actions/companies';
import Pagination from '../../components/Pagination';
import Select from '../../components/Select';
import info from '../../assets/icons/info.svg';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import TransferProofModal from './TransferProofModal';
import Loading from '../../components/Loading';

const TopupPlan = (props) => {
  const { list, meta } = props;
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const [paymentItem, setPaymentItem] = useState(null);
  const [params, setParams] = useState({
    populate: 'borrower_request.order,borrower_request.company_borrower_id,borrower_request.order.company_shipper.company_rating.badge_level,borrower_request.company_borrower_id.company_rating.badge_level,borrower_request.company_borrower_id.company_registration,borrower_request.company_borrower_id.bank_accounts,borrower_request.invoice.items.order.company_shipper.company_rating.badge_level,topup_plan,proof,borrower_request.invoice.items.order.transporter_shipper',
  });
  const [selectedTransporter, setSelectedTransporter] = useState(null);
  const [transporterOptions, setTransporterOptions] = useState([]);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [isDownloading, setIsDownloading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [activeTopupPlan, setActiveTopupPlan] = useState([]);
  const [openTransferProofModal, setOpenTransferProofModal] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getListCallback = (result) => {
    const res = _.get(result, 'payload.data');
    if (res && !res.errorMessage) {
      const rows = _.get(res, 'rows', [])
      if (rows && rows.length > 0) {
        const active_topup_plan = _.filter(rows, item => Boolean(_.get(item, 'topup_plan[0].status') === 'created'));
        setActiveTopupPlan(active_topup_plan);
        if (active_topup_plan.length > 0) {
          const total = _.reduce(active_topup_plan, (result, value) => {
            const request_amount = (_.get(value, 'borrower_request.request_amount', 0) || 0)
            return result + request_amount
          }, 0);
          setTotalTransaction(total);
        }
      }
    }
  }

  useEffect(() => {
    getList(0, params, getListCallback);
    
    props.getCompanies().then(result => {
      const res = _.get(result, 'payload.data');
      if (res && !res.errorMessage) {
        _.each(res, item => {
          item.label = item.name
          item.value = item.id
        })
        setTransporterOptions(res);

        if (res.length === 1) {
          const transporter = res[0]
          setSelectedTransporter(transporter);
          const new_params = {
            ...params,
            company_borrower_id: transporter.id
          }
          setParams(new_params)
          getTotalTransaction(new_params)
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const getList = (offset, params, callback) => {
    setIsFetching(true);
    props.getList(offset, params).then(result => {
      setIsFetching(false)

      if (callback) callback(result)
    })
  }

  const openPaymentModal = () => {
    setIsLoading(true);
    const body = {
      lend_request_ids: allData.map(item => item.id),
      company_borrower_id: selectedTransporter.id
    }
    
    props.createTopupPlan(body).then(result => {
      setIsLoading(false);
      if (_.get(result, 'payload.data.errorMessage')) {
        Swal.fire('Error!', result.payload.data.errorMessage, 'error');
      } else {
        setPaymentItem(result.payload.data);
        setOpenPaymentMethod(true);
      };
    });
  }

  const getTotalTransaction = (params) => {
    // if (!activeTopupPlan || activeTopupPlan.length === 0) {
      const data = []
      const batchDownloadSize = 50;
      getBatchData(params, 0, batchDownloadSize, data);
    // }
  };

  const getBatchData = (param, offset, limit, data) => {
    setIsDownloading(true);
    props.getBatchTopupPlan(offset, param, limit).then(results => {
      if (_.get(results, 'payload.data.errorMessage')) {
        setIsDownloading(false);
        Swal.fire('Error!', results.payload.data.errorMessage, 'error');
      } else {
        let result = _.get(results, 'payload.data.rows', []);
        data = _.concat(data, result);
        if (result.length < limit) {
          processBatchData(result)
          return;
        } else {
          getBatchData(param, offset+limit, limit, data);
        }
      };
    })
  };

  const processBatchData = (result) => {
    if (result.length === 0) {
      setIsDownloading(false);
      setTotalTransaction(0);
      setAllData([]);
      Swal.fire('', 'Tidak ada data', 'error');
      return;
    }
    
    const total = _.reduce(result, (result, value) => {
      const request_amount = (_.get(value, 'borrower_request.request_amount', 0) || 0)
      return result + request_amount
    }, 0);
    setTotalTransaction(total);
    setAllData(result);
    setIsDownloading(false);
  }

  const onChangeTransporter = value => {
    setSelectedTransporter(value)
    const new_params = {
      ...params,
      company_borrower_id: value.id
    }
    getList(0, new_params, getListCallback)
    setParams(new_params)
    getTotalTransaction(new_params)
  }

  const cancelPayment = () => {
    Swal.fire({
      text: 'Apakah Anda yakin untuk membatalkan pembayaran ini?',
      icon: 'info',
      showCancelButton: true,
      cancelButtonText: 'Tidak',
      confirmButtonText: 'Ya, Batalkan',
      reverseButtons: true,
      confirmButtonColor: '#27a397'
    }).then(result => {
      if (result.dismiss) return;

      setIsLoading(true)
      const id = _.get(activeTopupPlan, '[0].topup_plan[0].id');
      props.cancelTopupPlan(id).then(results => {
        setIsLoading(false)
        if (results.type === 'CANCEL_TOPUP_PLAN_FAIL') {
          Swal.fire('Error!', results.error.message, 'error');
        } else if (_.get(results, 'payload.data.errorMessage')) {
          Swal.fire('Error!', results.payload.data.errorMessage.split('\n')[0], 'error');
        } else {
          Swal.fire('Berhasil', `Rencana pembayaran berhasil dibatalkan`, 'success');
          getList(0, params, getListCallback)
        };
      })
    })
  }

  const ErrorButton = withStyles({
    root: {
      borderColor: '#F23D3D',
    },
    label: {
      color: '#F23D3D',
    }
  })(Button);

  return (
    <Page title="Rencana Pembayaran">
      <Loading open={isLoading} />
      <div
        className="container-content" 
        style={{marginBottom: activeTopupPlan.length > 0 ? '150px' : selectedTransporter ? '100px' : '20px'}}
      >
        <div className="row">
          <div className="col-12">
            <div className="s-24 bolder">Daftar Pembayaran</div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <div className="flex align-items-center">
              <div className="mr-3">Transporter</div>
              <div style={{ width: '260px' }}>
                <Select 
                  placeholder="Pilih transporter"
                  options={transporterOptions}
                  value={selectedTransporter}
                  onChange={(value) => onChangeTransporter(value)}
                  disabled={isDownloading}
                />
              </div>
            </div>
          </div>
        </div>

        {(!selectedTransporter && (!activeTopupPlan || activeTopupPlan.length === 0)) &&
          <div className="row my-3">
            <div className="col-12">
              <img src={info} alt="info" style={{ verticalAlign: 'sub' }} />
              &nbsp;&nbsp;
              <span className="text-ritase">
                Pilih Transporter untuk memulai pembayaran
              </span>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-12" style={{whiteSpace: 'nowrap'}}>
            <TableContainer className="table-card">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Shipper</TableCell>
                    <TableCell>Transporter</TableCell>
                    <TableCell>Tanggal Pengajuan</TableCell>
                    <TableCell>Tanggal Permintaan Pencairan</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Nilai Pembayaran</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFetching ?
                    <TableRow>
                      <TableCell align="center" colSpan={7} className="border-0">
                        Loading
                      </TableCell>
                    </TableRow>
                  : list.length > 0 ?
                    list.map((item, i) => {
                      const id = item.scf_type === 'invoice' ? 
                                 _.get(item, 'borrower_request.invoice.id', '-') : 
                                 _.get(item, 'borrower_request.order.id', '-');

                      const shipper = item.scf_type === 'invoice' ?
                                      item.borrower_request.invoice.target === "company_order" ?
                                        _.get(item, 'borrower_request.invoice.items[0].order.transporter_shipper', {})
                                        :
                                        _.get(item, 'borrower_request.invoice.items[0].order.company_shipper', {})
                                      :
                                      _.get(item, 'borrower_request.order.company_shipper', {});

                      const transporter = _.get(item, 'borrower_request.company_borrower_id', {});
                      const disbursement_date = item.scf_type === 'invoice' ? _.get(item, 'borrower_request.disbursement_date') : false
                      const disburse_countdown = disbursement_date ? getCountdown(disbursement_date) : '';

                      return (
                        <TableRow key={i}>
                          <TableCell>
                            <div>
                              { tdsSingkat(id) }
                            </div>
                            <div className="mt-3">
                              {_.get(item, 'id', false) ?
                                'SCF : ' + tdsSingkat(item.id)
                              :
                                '-'
                              }
                            </div>
                            {item.scf_type === 'invoice' ?
                              <Link to={{pathname: `/invoice/${item.id}`, state: { prevPath: 'invoice' }}} className="mt-5 text-ritase pointer block">Detail Invoice</Link>
                            :
                              <Link to={{pathname: `/dp/${item.id}`, state: { prevPath: 'dp' }}} className="mt-5 text-ritase pointer block">Detail Down Payment</Link>
                            }
                          </TableCell>
                          <TableCell>
                            <div className="flex align-items-center">
                              <div>
                                { _.get(shipper, 'name', '-') }
                              </div>
                              {_.get(shipper, 'company_type') === 'client' && 
                                <div className="level-badge-wrapper m-0 ml-2">
                                  {_.get(shipper, 'company_rating.badge_level') ?
                                    <div>
                                      {_.map(Array.from('x'.repeat(getSublevel(_.get(shipper, 'company_rating.badge_level')))), (x, i) => (
                                        <span 
                                          key={i}
                                          className={`level-badge ${getLevel(_.get(shipper, 'company_rating.badge_level'))}`}
                                        > </span>
                                      ))}
                                    </div>
                                  :
                                    <div>
                                      <span 
                                        className="level-badge no_badge"
                                      > </span>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="flex align-items-center">
                              <div>
                                {_.get(transporter, 'name', '-')}
                              </div>
                              {_.get(transporter, 'company_type') === 'transport' && 
                                <div className="level-badge-wrapper m-0 ml-2">
                                  {_.get(transporter, 'company_rating.badge_level') ?
                                    <div>
                                      {_.map(Array.from('x'.repeat(getSublevel(_.get(transporter, 'company_rating.badge_level')))), (x, i) => (
                                        <span 
                                          key={i}
                                          className={`level-badge ${getLevel(_.get(transporter, 'company_rating.badge_level'))}`}
                                        > </span>
                                      ))}
                                    </div>
                                  :
                                    <div>
                                      <span 
                                        className="level-badge no_badge"
                                      > </span>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          </TableCell>
                          <TableCell>
                            {_.get(item, 'borrower_request.created_at', false) ?
                              moment(item.borrower_request.created_at).format('ddd, DD MMM YYYY')
                            :
                              '-'
                            }
                          </TableCell>
                          <TableCell>
                            {disbursement_date ?
                              <div>
                                <div>
                                  {moment(disbursement_date).format('ddd, DD MMM YYYY')}
                                </div>
                                <div 
                                  className={disburse_countdown > 0 ? "text-danger bolder" : disburse_countdown > -5 ? "text-danger" : disburse_countdown <= -60 ? "text-ritase" : "text-grey"}
                                >
                                  ( {disburse_countdown > 0 ? 'Overdue' : `${Math.abs(disburse_countdown)} hari lagi`} )
                                </div>
                              </div>
                            : item.scf_type === 'invoice' && _.get(item, 'borrower_request.scf_top', false) ?
                              `H + ${item.borrower_request.scf_top}`
                            :
                              '-'
                            }
                          </TableCell>
                          <TableCell>
                            {_.get(item, 'status', false) ?
                              <div>
                                <div className='status-badge blue' style={{maxWidth: '100px', whiteSpace: 'normal'}}>{_.capitalize(item.status)}</div>
                                {_.get(item, 'topup_plan[0].status') === 'created' &&
                                  <div style={{ fontSize: '12px', color: '#009ABF', marginTop: '5px' }}>
                                    Menunggu {_.get(item, 'proof.length', 0) > 0 ? 'Verifikasi' : 'Pembayaran'}
                                  </div>
                                }
                              </div>
                            :
                              '-'
                            }
                          </TableCell>
                          <TableCell>
                            <div className="text-action s-18 bolder">
                              {formatRupiah(_.get(item, 'borrower_request.request_amount', 0))}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  :
                    <TableRow>
                      <TableCell align="center" colSpan={7} className="border-0">
                        No Data Found
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      
        <Pagination 
          count={meta.count || 0}
          limit={meta.limit || 10}
          offset={meta.offset || 0}
          onChange={(e, page) => getList(((page - 1) * 10), params)}
        />
      </div>

      {(selectedTransporter && (!activeTopupPlan || activeTopupPlan.length === 0)) &&
        <Footer className="flex justify-content-end">
          <div>
            <span>Jumlah Transaksi : </span>
            <strong className="s-16">{ isDownloading ? 'Calculating...' : (allData.length || 0) }</strong>
          </div>
          <div className="mx-4">
            <span>Total Nilai Pembayaran : </span>
            <strong className="s-16">{ isDownloading ? 'Calculating...' : formatRupiah(totalTransaction) }</strong>
          </div>
          <div>
            <Button 
              variant="contained" 
              color="secondary" 
              disableElevation
              style={{ width: '124px' }}
              onClick={() => openPaymentModal()}
              disabled={isDownloading}
            >Bayar</Button>
          </div>
        </Footer>
      }

      {activeTopupPlan.length > 0 &&
        <Footer>
          <div className="block w-100">
            <div className="flex justify-content-between align-items-center">
              <div>
                Saat ini anda memiliki transaksi yang sedang menunggu {_.get(activeTopupPlan, '[0]proof.length', 0) > 0 ? 'verifikasi' : 'pembayaran'}.
              </div>
              <div className="flex">
                <div>
                  <span>Jumlah Transaksi : </span>
                  <strong className="s-16">{ activeTopupPlan.length || 0 }</strong>
                </div>
                <div className="ml-4">
                  <span>Total Nilai Pembayaran : </span>
                  <strong className="s-16">{ formatRupiah(totalTransaction) }</strong>
                </div>
              </div>
            </div>
            {_.get(activeTopupPlan, '[0].proof.length', 0) <= 0 &&
              <div>
                <hr />
                <div className="flex justify-content-between">
                  <div>
                    <ErrorButton 
                      variant="outlined" 
                      disableElevation
                      onClick={cancelPayment}
                    >Batalkan Pembayaran</ErrorButton>
                  </div>
                  <div>
                    <span className="mr-2">
                      Anda sudah melakukan pembayaran?
                    </span>
                    <Button 
                      variant="outlined" 
                      color="primary"
                      disableElevation
                      onClick={() => setOpenTransferProofModal(true)}
                    >Upload Bukti Transfer</Button>
                  </div>
                </div>
              </div>
            }
          </div>
        </Footer>
      }

      {openPaymentMethod &&
        <PaymentMethodModal
          open={openPaymentMethod}
          closeModal={() => setOpenPaymentMethod(false)}
          item={paymentItem}
          onFinish={() => getList(meta.offset, params, getListCallback)}
          paymentType="xendit"
          isTopupPlan={true}
        />
      }

      {openTransferProofModal &&
        <TransferProofModal
          open={openTransferProofModal}
          closeModal={() => setOpenTransferProofModal(false)}
          data={activeTopupPlan}
          totalTransaction={totalTransaction}
          onFinish={() => getList(meta.offset, params, getListCallback)}
        />
      }
    </Page>
  );
};

const mapStateToProps = state => {
  let { list, meta } = state.topup_plan;
  return {
    list, meta
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getList: (offset, params) => dispatch(getTopupPlan(offset, params)),
    getCompanies: (params) => dispatch(getCompanies(params)),
    createTopupPlan: (params) => dispatch(createTopupPlan(params)),
    getBatchTopupPlan: (offset, params, limit) => dispatch(getBatchTopupPlan(offset, params, limit)),
    cancelTopupPlan: (id) => dispatch(cancelTopupPlan(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopupPlan);