import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import _ from 'lodash';

const DocumentsModal = (props) => {
  const { open, closeModal, data } = props;
  const files = [
    { 
      id: 'ktp', 
      label: 'KTP Pengurus Perusahaan', 
      file_url: _.get(data, 'ktp_file', '') 
    },
    { 
      id: 'siup', 
      label: 'SIUP', 
      file_url: _.get(data, 'siup_file', '') 
    },
    { 
      id: 'npwp', 
      label: 'NPWP', 
      file_url: _.get(data, 'npwp_file', '') 
    },
    { 
      id: 'tdp', 
      label: 'TDP', 
      file_url: _.get(data, 'tdp_file', '') 
    },
    { 
      id: 'domisili', 
      label: 'Surat Domisili Perusahaan', 
      file_url: _.get(data, 'domisili_file', '') 
    },
    { 
      id: 'akta_pendirian', 
      label: 'Akta Pendirian', 
      file_url: _.get(data, 'akta_pendirian_file', '') 
    },
    { 
      id: 'akta_perubahan', 
      label: 'Akta Perubahan', 
      file_url: _.get(data, 'akta_perubahan_file', '') 
    },
  ];

  const downloadFile = (url) => {
    window.location.href = url;
  }

  return (
    <Dialog
      open={open}
      onClose={() => closeModal()}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle onClose={() => closeModal()} className="border-bottom">
        Dokumen Legal
        <Close className="pointer pull-right" onClick={() => closeModal()} />
      </DialogTitle>
      <DialogContent className="p-4">
        {files.map(file => (
          <div key={file.id}>
            <div className="row align-items-center">
              <div className="col-8">{file.label}</div>
              <div className="col-4 text-right">
                {file.file_url ?
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    disableElevation 
                    onClick={() => downloadFile(file.file_url)}
                  >Download</Button>
                :
                  '-'
                }
              </div>
            </div>
            <hr />
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsModal;