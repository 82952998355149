import { GET } from "../actions/banks";
import _ from 'lodash';

const banks = (state = [], action) => {
  switch (action.type) {
    case GET + '_SUCCESS':
      return _.get(action, 'payload.data.rows', []);
    case GET + '_FAIL':
      return [];
    default:
      return state;
  }
}

export default banks;